import React, { memo, useState, useMemo, useEffect, useContext } from "react";
import { Pane, WMSTileLayer, GeoJSON, useMapEvent } from "react-leaflet";
import { getPointBaseLayer } from "../../../utils/api";
import * as strings from "../../../utils/strings";
import CustomWMSLayer from "./CustomWMSLayer";
import { Context } from "../../../GlobalContext";
import { africanCountries } from "../../../utils/misc";

const {
  ELECTRICITY_GRID,
  FIBRE_OPTIC_LINES,
  ROAD_NETWORK,
  CELL_TOWERS,
  EDUCATION,
  HEALTHCARE,
  EARTHQUAKES_MAGNITUDE,
  TSUNAMI_EVENT,
  SIGNIFICANT_EARTHQUAKES,
  SIGNIFICANT_VOLCANIC_ERUPTION,
  WIND,
} = strings;

const getZoomLevel = (zoom) => {
  if (zoom === 5 || zoom === 6 || zoom < 5) {
    return 10000;
  }
  if (zoom === 7 || zoom === 9 || zoom === 8) {
    return 999;
  }
  if (zoom === 10 || zoom === 11) {
    return 99;
  }
  if (zoom === 12) {
    return 9;
  }
  if (zoom === 13 || zoom === 14) {
    return 6;
  }
  if (zoom >= 15) {
    return 3;
  }
};

const windUrl = "http://irena-ga.azurewebsites.net/geoserver/vaisala/ows?SERVICE=WMS&";
const earthquakesUrl = "https://onegeology-asia.org/ows/GSJ_ASIA_Earthquake_Source_Region/wms";
const earthquakeLayer = "ASIA_GSJ_10M_Earthquake_Source_Region";
const windSpeedLayer = "global_windspeed_80m";
const outputFormat = "outputFormat=application/json";

const layerProps = {
  format: "image/png",
  transparent: true,
  tiled: true,
  info_format: "text/html",
  request: "GetFeatureInfo",
  className: "custom-layer",
  tileSize: 4000,
};

const BackgroundLayers = memo(() => {
  const { selectedDatasets, countryName } = useContext(Context);
  const [zoom, setZoom] = useState(6);
  const [geoJsonData, setGeoJsonData] = useState([]);
  const selectedCountry = countryName.split(" ").join("_");

  // GeoJson data url parameter
  const wmsLayerUrl = `${strings.geoserverUrl}/geoserver/wms`;
  const baseUrl = `${strings.geoserverUrl}/geoserver/topp/ows`;

  const filter = ({ lat, lng }) => {
    return `DWITHIN(wkb_geometry,POINT(${lng} ${lat}),${getZoomLevel(zoom)},meters)`;
  };

  const getUrl = (dataset) => {
    if (dataset === EARTHQUAKES_MAGNITUDE) {
      return earthquakesUrl;
    } else if (dataset.includes("wind")) {
      return windUrl;
    } else {
      return `${strings.geoserverUrl}/geoserver/wms`;
    }
  };

  const dataLayers = useMemo(
    () => ({
      [CELL_TOWERS]: `topp:${selectedCountry}_cell_towers`,
      [EDUCATION]: `topp:${selectedCountry}_education_facilities`,
      [HEALTHCARE]: `topp:${selectedCountry}_health_facilities`,
      [ELECTRICITY_GRID]: `topp:${selectedCountry}_electricity_grid`,
      [FIBRE_OPTIC_LINES]: `topp:${selectedCountry}_fibre_optics`,
      [ROAD_NETWORK]: `topp:${selectedCountry}_road_network`,
      [SIGNIFICANT_VOLCANIC_ERUPTION]: "topp:significant_volcanic_events",
      [SIGNIFICANT_EARTHQUAKES]: "topp:significant_earthquakes",
      [TSUNAMI_EVENT]: "topp:significant_tsunami_events",
      [EARTHQUAKES_MAGNITUDE]: earthquakeLayer,
    }),
    [selectedCountry]
  );

  const setZIndex = ({ layerName, dataset }) => {
    if (
      layerName === "topp:significant_earthquakes" ||
      layerName === "topp:significant_volcanic_eruptions" ||
      layerName === "topp:tsunami_source_event" ||
      layerName === `topp:${selectedCountry}_cell_towers` ||
      layerName === `topp:${selectedCountry}_education_facilities` ||
      layerName === `topp:${selectedCountry}_health_facilities`
    ) {
      return 405;
    } else if (dataset.includes(WIND)) {
      return 100;
    } else {
      return 403;
    }
  };

  // Show layers on depending on their order
  const layers = useMemo(() => {
    const filteredLayers = Object.entries(dataLayers)
      .filter(([dataset]) => selectedDatasets.includes(dataset))
      .map(([dataset, layerName]) => {
        const url = getUrl(dataset);
        const selectedLayers =
          africanCountries.includes(countryName) &&
          dataset === "fibre-optic-lines"
            ? "topp:mozambique_fibre_optics"
            : layerName;

        return (
          <WMSTileLayer
            key={dataset}
            url={url}
            layers={selectedLayers}
            transparent={true}
            format="image/png"
            zIndex={setZIndex({ layerName, dataset })}
          />
        );
      });

    return filteredLayers;
    // eslint-disable-next-line
  }, [dataLayers, selectedDatasets]);

  const arrLayers = layers.map((layer) => layer.props.layers);

  const layerNames = useMemo(() => arrLayers.join(","), [arrLayers]);

  const selectedLayer = arrLayers.map((layer) => layer.split("topp:").join(""));

  const layerNamePerCountry = selectedLayer.map((layer) => {
    const arrOfText = layer.split("_");
    return `${selectedCountry}_${arrOfText[1]}_${arrOfText[2]}`;
  });

  const getFeature = async ({ lat, lng }) => {
    try {
      const response = await fetch(
        `${baseUrl}?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerNames}&maxFeatures=1&${outputFormat}&CQL_FILTER=${filter(
          { lat, lng }
        )}`
      );
      const data = await response.json();
      setGeoJsonData(data?.features);
    } catch (error) {
      return null;
    }
  };

  useMapEvent({
    click: async (event) => {
      const latlng = event.latlng;
      const { lat, lng } = latlng;
      if (layerNamePerCountry) {
        getFeature({ lat, lng });
      }
    },
    zoomend: (event) => setZoom(event.target._zoom),
  });

  // Style GeoJson layer
  const onEachData = (feature, layer) => {
    const isPointBasedLayer = getPointBaseLayer(feature);

    const defaultLineStyle = { color: "#1D76D2", opacity: 1, weight: 5 };

    !isPointBasedLayer && layer.setStyle(defaultLineStyle);
  };

  useEffect(() => {
    setGeoJsonData([]);
  }, [selectedCountry]);

  const localLayers = arrLayers.filter(
    (layer) => !layer.includes(WIND) && !layer.includes(earthquakeLayer)
  );

  return (
    <>
      {/* Main layer */}
      <Pane name="background-layers">
        {layers}

        {/* Hovered layer */}
        {geoJsonData.length > 0 &&
          layerNamePerCountry.includes(geoJsonData[0]?.id.split(".")[0]) && (
            <Pane name="background-layer" style={{ zIndex: 403 }}>
              <GeoJSON
                key={geoJsonData[0].id}
                data={geoJsonData}
                onEachFeature={onEachData}
              />
            </Pane>
          )}
      </Pane>

      {/* Wind layer */}
      {selectedDatasets.includes(WIND) && (
        <Pane name="wind">
          <WMSTileLayer
            url={windUrl}
            layers={windSpeedLayer}
            transparent={true}
            format="image/png"
          />
        </Pane>
      )}

      {/* Information about the clicked layer */}
      <CustomWMSLayer
        url={wmsLayerUrl}
        layers={localLayers}
        options={layerProps}
      />

      {selectedDatasets.includes(WIND) && (
        <CustomWMSLayer
          url={windUrl}
          layers={[windSpeedLayer]}
          options={layerProps}
        />
      )}

      {selectedDatasets.includes(EARTHQUAKES_MAGNITUDE) && (
        <CustomWMSLayer
          url={earthquakesUrl}
          layers={[earthquakeLayer]}
          options={layerProps}
        />
      )}
    </>
  );
});

export default BackgroundLayers;
