export const getSeriesByUrbanization = (
  rural2020,
  urban2020,
  rural2030,
  urban2030
) => {
  return [
    {
      data: rural2020,
      label: "2020 Rural",
    },
    {
      data: urban2020,
      label: "2020 Urban",
    },
    {
      data: rural2030,
      label: "2030 Rural",
    },
    {
      data: urban2030,
      label: "2030 Urban",
    },
  ];
};

export const getSeriesByGender = (
  female2020,
  male2020,
  female2030,
  male2030
) => {
  return [
    {
      data: female2020,
      label: "2020 Female",
    },
    {
      data: male2020,
      label: "2020 Male",
    },

    {
      data: female2030,
      label: "2030 Female",
    },
    {
      data: male2030,
      label: "2030 Male",
    },
  ];
};

export const getAffordabilitySeries = (
  min2020,
  avg2020,
  max2020,
  min2030,
  avg2030,
  max2030,
  dataIndex
) => {
  const formatLabel = (string) => {
    const arrOfText = string.split("/");
    return dataIndex === 0 ? arrOfText[0] : arrOfText[1];
  };

  return [
    {
      data: min2020,
      label: formatLabel("2020 Minimum/1Gb"),
    },
    {
      data: avg2020,
      label: formatLabel("2020 Average/5Gb"),
    },
    {
      data: max2020,
      label: formatLabel("2020 Maximum/10Gb"),
    },
    {
      data: min2030,
      label: formatLabel("2030 Minimum/1Gb"),
    },
    {
      data: avg2030,
      label: formatLabel("2030 Average/5Gb"),
    },
    {
      data: max2030,
      label: formatLabel("2030 Maximum/10Gb"),
    },
  ];
};
