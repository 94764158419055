import { useContext, useState } from "react";
import {
  Button,
  Typography,
  Toolbar,
  Box,
  AppBar,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import {
  DarkModeRounded,
  LightModeRounded,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./header.scss";

import { Context } from "../../GlobalContext";

import { CELL_TOWER_ICON } from "../../utils/strings";
import { HOME, REPORT } from "../../utils/path";

import SearchCountry from "../Forms/SearchCountry/SearchCountry";

export const Header = () => {
  const {
    ituDataList,
    isShownModal,
    setIsShownModal,
    isOpenedDrawer,
    setIsOpenedDrawer,
    existingData,
    darkMode,
    setDarkMode,
    setCacheCleaner,
  } = useContext(Context);
  const navigation = useNavigate();
  const [isOpenedMenu, setIsOpenedMenu] = useState(false);
  const mobileMenu = document.querySelector(".mobile-menu");
  const { pathname } = useLocation();
  const isReport = pathname === REPORT;

  const isShownButtons = ituDataList?.length > 0 && !isReport ;

  const icon = darkMode ? <DarkModeRounded /> : <LightModeRounded />;

  const navigateToReport = () => {
    setCacheCleaner(true);

    setTimeout(() => {
      navigation(REPORT, { replace: true });
    }, 50);

    if (isOpenedMenu) {
      setIsOpenedMenu(false);
    }
  };

  const getClassName = (isActive) => {
    return `menu-button ${isActive} && "activated-button"`;
  };

  return (
    <Box className="header">
      <AppBar position="fixed">
        <Toolbar>
          <Link className="title" to={HOME} replace>
            <Typography className="title" noWrap component="h1">
              Broadband Demand Mapping Tool
            </Typography>
          </Link>
          <Link to={HOME} className="broadband-mapping-logo">
            <img
              src={CELL_TOWER_ICON}
              alt="cell tower location"
              loading="lazy"
            />
          </Link>

          <SearchCountry />

          <Box className="header-separator" />

          <Box className="header-button-wrapper">
            <Button
              size="large"
              edge="end"
              className="header-button"
              onClick={() => setIsShownModal(!isShownModal)}
            >
              About
            </Button>

            {isShownButtons && (
              <>
                <Button
                  size="large"
                  edge="end"
                  className="header-button"
                  onClick={navigateToReport}
                >
                  Report
                </Button>

                <Button
                  size="large"
                  edge="end"
                  disabled={!existingData}
                  className={`header-button filter-button ${isOpenedDrawer ? "activated-button" : ""}`}
                  onClick={() => setIsOpenedDrawer(!isOpenedDrawer)}
                >
                  Filters
                </Button>
              </>
            )}

            <IconButton
              size="large"
              className="theme-button"
              aria-label="theme-button"
              onClick={() => setDarkMode(!darkMode)}
            >
              {icon}
            </IconButton>
          </Box>

          {/* Menu for mobile  */}
          <Box className="mobile-menu">
            <IconButton
              aria-label="menu"
              onClick={() => setIsOpenedMenu(!isOpenedMenu)}
            >
              <MenuIcon />
            </IconButton>

            <Menu
              anchorEl={mobileMenu}
              open={isOpenedMenu}
              onClose={() => setIsOpenedMenu(false)}
              className="mobile-menu"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                className={getClassName(isShownModal)}
                disabled={!existingData}
                onClick={() => {
                  setIsShownModal(!isShownModal);
                  setIsOpenedMenu(false);
                }}
              >
                About
              </MenuItem>

              {isShownButtons && (
                <MenuItem
                  className={getClassName(isShownModal)}
                  disabled={!existingData}
                  onClick={navigateToReport}
                >
                  Report
                </MenuItem>
              )}

              {isShownButtons && (
                <MenuItem
                  className={`filter-button ${getClassName(isOpenedDrawer)}`}
                  onClick={() => {
                    setIsOpenedDrawer(!isOpenedDrawer);
                    setIsOpenedMenu(false);
                  }}
                >
                  Filters
                </MenuItem>
              )}

              <MenuItem
                className="menu-button theme-button"
                onClick={() => {
                  setDarkMode(!darkMode);
                  setIsOpenedMenu(false);
                }}
              >
                {icon}
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
