import { Box, Checkbox, CircularProgress, FormControlLabel } from "@mui/material";
import { memo, useContext } from "react";
import { Context } from '../../../GlobalContext';

import { className, EARTHQUAKES_MAGNITUDE } from "../../../utils/strings";
import { africanCountries } from "../../../utils/misc";

const CheckboxGroupContent = memo(({
  value,
  name,
  loading,
  getSelectedGroups,
  getScrollPostion,
}) => {
  const { selectedDatasets, countryName } = useContext(Context);
  const { SUB_SECTION, FORM_CONTROL_LABEL, TOGGLE_BUTTON } = className;
  const isLoading = loading && selectedDatasets.includes(value);
  const isAfricanCountry = africanCountries.includes(countryName);

  const getNaturalHazard = () => {
    if (isAfricanCountry && value === EARTHQUAKES_MAGNITUDE) {
      return null
    } else {
      return (
        <FormControlLabel
          className={`${FORM_CONTROL_LABEL} ${name.length > 20 ? "long-label": "short-label"}`}
          onChange={(event) => {
            getSelectedGroups(event.target.value);
            getScrollPostion();
          }}
          value={value}
          label={name}
          disabled={isLoading}
          control={
            isLoading ? (
              <CircularProgress value={null} className="controller-loading" />
            ) : (
              <Checkbox
                id={name}
                className={TOGGLE_BUTTON}
                size="small"
                checked={selectedDatasets.includes(value)}
              />
            )
          }
        />
      );
    }
  };

  return <Box className={SUB_SECTION}>{getNaturalHazard()}</Box>;
});

export default CheckboxGroupContent;