import { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  List,
  ListItem,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import "./overview.scss";
import { Context } from "../../../GlobalContext";
import { fetchData } from "../../../utils/api";
import countries from "../../../utils/countries.json";
import { POPULATION, baseUrl, mapUrl, attribution } from "../../../utils/strings";
import { reportZoomLevel, roundValue } from "../../../utils/numbers";

const Overview = () => {
  const overviewInitialData = {
    districtsCount: 0,
    provincesCount: 0,
    maximumFixed: 0,
    minimumFixed: 0,
    maximumActiveMobile: 0,
    minimumActiveMobile: 0,
    grossIncome: 0,
    populationDensity: 0,
    cellTowers: null,
  }
  const { setMap, position, setMessage, countryName, isLoadPdf } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [overviewData, setOverviewData] = useState(overviewInitialData);

  const { districtsCount, provincesCount, maximumFixed, minimumFixed, maximumActiveMobile, minimumActiveMobile, grossIncome, populationDensity, cellTowers} = overviewData;

  const [polygons, setPolygons] = useState(null);
  const { lat, lon } = position;
  const totalCellTowers = cellTowers?.reduce((acc, cellTower) => acc + cellTower.count, 0);
  const cellTowerPercentage = cellTowers?.map((cellTower) => {
    return {
      ...cellTower,
      label: cellTower.type,
      value: roundValue((cellTower.count / totalCellTowers) * 100),
    };
  });

  const newCountryName = countryName.split(" ").join("_");

  const isLoading = loading ? "loading..." : "Unknown";

  const getOverviewContent = async () => {
    setLoading(true);

    const data = await fetchData(`${baseUrl}:8888/api/overview?country_name=${newCountryName}`, setMessage);

    if (data && !data?.error) {
      setOverviewData(data);
    } else {
      setOverviewData(overviewInitialData);
    }

    setLoading(false);
  }

  // Overview table contents
  const selectedCountry = countries.find(({ name }) => {
    const { official, common } = name;
    const officialName = official.toLowerCase();
    const commonName = common.toLowerCase();
    const selectedCountry = commonName !== countryName ? officialName : commonName;

    return selectedCountry === countryName;
  });

  const countryOverview = [
    { name: "Area", value: selectedCountry?.area ? <>{selectedCountry?.area} km<sup>2</sup></> : "Unknown" },
    {
      name: "Capital",
      value: selectedCountry?.capital ? selectedCountry?.capital[0] : "Unknown",
    },
    {
      name: "Population density",
      value: populationDensity ? <>{roundValue(populationDensity, POPULATION)} pers./km<sup>2</sup></> : "Unknown",
    },
    {
      name: "Per capita gross income",
      value: grossIncome ? `${grossIncome?.toFixed(2)} USD` : isLoading,
    },
  ];

  const regionalDemand = [
    { name: "Number of districts", value: districtsCount || "Unknown", location: "" },
    {
      name: "Number of provinces/regions",
      value: provincesCount || "Unknown",
      location: "",
    },
    {
      name: "Maximum fixed broadband subscriptions (%)",
      value: roundValue(Number(maximumFixed?.value)) || "Unknown",
      location: maximumFixed?.location,
    },
    {
      name: "Minimum fixed broadband subscriptions (%)",
      value: roundValue(Number(minimumFixed?.value)) || "Unknown",
      location: minimumFixed?.location,
    },
    {
      name: "Maximum active mobile subscriptions (%)",
      value: roundValue(Number(maximumActiveMobile?.value)) || "Unknown",
      location: maximumActiveMobile?.location,
    },
    {
      name: "Minimum active mobile subscriptions (%)",
      value: roundValue(Number(minimumActiveMobile?.value)) || "Unknown",
      location: minimumActiveMobile?.location,
    },
  ];

  // Map layers styles
  const onEachFeatures = (_feature, layer) => {
    const style = {
      fillColor: "#0A2864",
      weight: 3,
      color: "#fff",
      fillOpacity: 1,
    };

    layer.setStyle(style);
  };

  // Get polygons
  const getPolygons = async () => {
    const data = await fetchData(`${baseUrl}:8888/api/polygons?country_name=${newCountryName}`, setMessage);

    if (data && !data?.error) {
      setPolygons(data);
    } else {
      setPolygons(null);
    }
  };

  useEffect(() => {
    getPolygons();
    getOverviewContent();

    // eslint-disable-next-line
  }, [newCountryName]);

  return (
    <Card className="card overview-card">
      <MapContainer
        className="report-map"
        center={[lat, lon]}
        zoom={reportZoomLevel}
        ref={setMap}
        scrollWheelZoom={false}
        zoomControl={false}
        dragging={false}
        doubleClickZoom={false}
      >
        <TileLayer {...{ attribution, url: mapUrl }} />
        {polygons && polygons?.features && (
          <GeoJSON
            key={polygons?.features?.length}
            data={polygons}
            onEachFeature={onEachFeatures}
          />
        )}
      </MapContainer>

      <Box>
        <Box className="country-overview section">
          <CardHeader title="Country Overview (2020)" className="card-header" />
          <TableContainer component="table">
            <TableBody>
              {countryOverview.map(({ name, value }) => (
                <TableRow key={name}>
                  <TableCell component="th" scope="row">
                    {name}
                  </TableCell>
                  <TableCell align="right">{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
        </Box>

        {cellTowers?.length > 0 && (
          <Box className="broadband-infrastructures section">
            <Typography component="h3">Broadband Infrastructures</Typography>

            <Typography component="p" className="cell-tower-density">
              <b>Density of cell towers:</b> {totalCellTowers}/km<sup>2</sup>
            </Typography>

            <Typography component="p" className="technology-type">
              <b>Coverage by type of technology</b>
            </Typography>

            <PieChart
              className="technology-chart"
              series={[
                {
                  id: "sync",
                  data: cellTowerPercentage,
                  valueFormatter: (item) => `${item?.value}%`,
                  arcLabel: (item) => `${item.value}%`,
                  highlightScope: { highlighted: "item", faded: "global" },
                },
              ]}
              width={400}
              height={170}
              margin={{ left: 0, right: 5 }}
              slotProps={{
                legend: {
                  direction: "column",
                  position: { vertical: "top", horizontal: "left" },
                  itemMarkWidth: 16,
                  itemMarkHeight: 16,
                  labelStyle: { fontSize: 14 },
                }
              }}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: isLoadPdf ? "#aaaaaa" : "white",
                  fontWeight: "bold",
                  fontSize: 14,
                  textShadow: "0px 0px 5px black"
                },
              }}
            />
          </Box>
        )}

        <Box className="regional-demand section">
          <Typography component="h3">Regional demand</Typography>
          <List>
            {regionalDemand.map(({ name, value, location }) => (
              <ListItem key={name}>
                {name}: <b>{value || 0}</b>
                {location && (<>in<b>{location}</b></>)}
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Card>
  );
};

export default Overview;