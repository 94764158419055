import { useContext, useRef } from "react";
import { Box, CircularProgress, Fab, Tooltip, Typography } from "@mui/material";
import { Download } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";

import "./report.scss";

import { Context } from "../../GlobalContext";

import Overview from "./Overview/Overview";
import CountryLevel from "./CountryLevel/CountryLevel";
import SubNationalLevel from "./SubNationalLevel/SubNationalLevel";

const Report = () => {
  const { countryName, countries, isLoadPdf, setIsLoadPdf } =
    useContext(Context);

  const contentRef = useRef();
  const selectedCountry = countries?.find((country) => country?.toUpperCase() === countryName?.toUpperCase());

  const printReport = useReactToPrint({
    contentRef,
    documentTitle: `${countryName}-report`,
    bodyClass: "report-to-pdf",
    suppressErrors: true,
  });

  const printPage = () => {
    setIsLoadPdf(true);
    const page = document.querySelector(".report");
    const body = document.querySelector("body");
    page.classList.add("customized-report");
    body.style.overflow = "hidden";
    setTimeout(() => {
      printReport();
      page.classList.remove("customized-report");
      setIsLoadPdf(false);
      body.style.overflow = "unset";
    }, [1500]);
  };

  return (
    <Box className="report" ref={contentRef}>
      <Typography component="h2">
        {selectedCountry ? selectedCountry : countryName}
      </Typography>

      <Tooltip title="Download report into PDF" placement="left">
        <Fab
          size="medium"
          aria-label="download"
          className="floating-button"
          onClick={printPage}
        >
          <Download />
        </Fab>
      </Tooltip>

      <Box className="report-main-content">
        <Overview />
        <CountryLevel />
        <SubNationalLevel />
      </Box>
      {isLoadPdf && (
        <div className="document-mask">
          <div>
            <CircularProgress size="60px" />
            <Typography component="p">Preparing page to print</Typography>
          </div>
        </div>
      )}
    </Box>
  );
};

export default Report;
