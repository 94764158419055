import { Route, Routes } from "react-router-dom";

import { HOME, REPORT } from "./utils/path";

import Header from "./components/Header/Header";
import About from "./components/About/About";
import Error from "./components/Error/Error";
import Report from "./view/Report/Report";
import Home from "./view/Home/Home";

function App() {
  return (
    <>
      <Header />

      <About />

      <Error />

      <Routes>
        <Route exact path={HOME} element={<Home />} />
        <Route exact path={REPORT} element={<Report />} />
      </Routes>
    </>
  );
}

export default App;
