import { barLabelClasses, chartsGridClasses } from "@mui/x-charts";
import { roundValue } from "./numbers";

// Helper function to format values based on affordability threshold
const formatValue = (value) =>
  value > 100 ? `Unaffordable (${roundValue(value)}%)` : `${roundValue(value)}%`;

// Highlights the bar and formats its values
export const highlightBar = (series) =>
  series?.map((data) => ({
    ...data,
    highlightScope: { highlighted: "item" },
    valueFormatter: formatValue,
  }));

// Helper function for bar label formatting, adjusting based on dimensions
const formatBarLabel = (item, context) => {
  const { height, width } = context.bar;
  return height < 20 || width < 20 ? null : `${roundValue(item?.value)}%`;
};

// Generates default chart properties, with customization for layout, margins, and grid
export const getChartProps = ({ data, height = 300, loading, hasData, isLoadPdf }) => {
  const margin = hasData
    ? { top: 5, right: 0.8 }
    : { top: 0, right: 0.8, left: 0.8 };
  const grid = hasData
    ? { horizontal: true, vertical: true }
    : { horizontal: false, vertical: false };

  return {
    layout: "vertical",
    yAxis: [
      {
        scaleType: "linear",
        valueFormatter: (value) => `${value}%`,
        tickFontSize: 10,
      },
    ],
    xAxis: [
      {
        scaleType: "band",
        data,
        barGapRatio: 0.08,
        categoryGapRatio: 0.1,
      },
    ],
    barLabel: formatBarLabel,
    loading,
    height,
    margin,
    borderRadius: 4,
    grid,
    tooltip: { trigger: "item" },
    slotProps: {
      legend: { hidden: true },
    },
    sx: {
      [`& .${chartsGridClasses.horizontalLine}`]: {
        strokeDasharray: "2 3",
        strokeWidth: 1,
      },
      [`& .${barLabelClasses.root}`]: {
        fill: isLoadPdf ? "#aaaaaa" : "white",
        fontSize: 10,
        fontWeight: "bold",
        textShadow: "0px 0px 5px black",
      },
    },
  };
};