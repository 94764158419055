import { Box, Paper } from "@mui/material";

const PanelContainer = ({ children, className = "" }) => {
  // USe leaflet panel class name for consistency
  const panelClassName = `leaflet-control-layers leaflet-control-layers-expanded leaflet-control custom-controller-wrapper ${className}`;
  return (
    <Paper className={panelClassName}>
      <Box className="leaflet-control-layers-overlays">{children}</Box>
    </Paper>
  );
};

export default PanelContainer;
