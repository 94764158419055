import { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { CloseOutlined, LegendToggleOutlined } from "@mui/icons-material";
import "./legend.scss";
import { roundValue } from "../../../../utils/numbers";
import OpenPanelButton from "../../../OpenPanelButton/OpenPanelButton";

const Legend = ({ title, colors, ranges, name = "" }) => {
  const screenWidth = window.innerWidth;
  const [isOpenedPanel, setIsOpenedPanel] = useState(screenWidth > 795);
  const minRanges = ranges?.filter(
    (range) => range !== ranges[ranges.length - 1]
  );
  const maxRanges = ranges?.filter((range) => range !== ranges[0]);

  const hiddenLegend = `${!isOpenedPanel && "closed-legend"} ${
    minRanges?.length === 0 ? "hidden-legend" : ""
  }`;

  const getAffordabilityMax = (value) => name === "affordability" && value > 100;

  return (
    <Box className={`broadband-indicator-legend legend ${hiddenLegend}`}>
      {!isOpenedPanel && (
        <OpenPanelButton
          title="Display legend"
          placement="left"
          Icon={LegendToggleOutlined}
          setIsOpenedPanel={() => setIsOpenedPanel(true)}
        />
      )}
      {isOpenedPanel && (
        <>
          <Box className="broadband-indicator-legend-header">
            <Typography component="h4">{title}</Typography>
            <IconButton
              className="close-button"
              onClick={() => setIsOpenedPanel(false)}
            >
              <CloseOutlined fontSize="inherit" />
            </IconButton>
          </Box>

          {minRanges?.map((range, index) => {
            const color =
              (index + 1 || index) > colors.length
                ? colors[colors.length - 1]
                : colors[index];

            const colorName = getAffordabilityMax(Number(maxRanges[maxRanges.length - 1])) ? "Unaffordable" : name;

            return (
              <Box key={index}>
                <i style={{ opacity: 1, backgroundColor: color }}></i>
                {roundValue(Number(range), colorName)} &#8211;{" "}
                {getAffordabilityMax(roundValue(Number(maxRanges[index]), colorName)) ? "Unaffordable" : roundValue(Number(maxRanges[index]), colorName)}
                <br />
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
};

export default Legend;
