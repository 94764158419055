import React from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import { useContext } from "react";
import { Context } from '../../../../../GlobalContext';
import { countriesString } from '../../../../../utils/strings';

const FibreOpticLines = () => {
  const { countryName } = useContext(Context);
  const isMozambique = countryName === countriesString.MOZAMBIQUE.toLowerCase();

  const renderListItem = (status, label) => (
    <ListItem className="list-item">
      <span className={`status-color ${status} round-status`} />
      {label}
    </ListItem>
  );

  return (
    <Box className="fibre-optic-legend legend-item">
      <Typography component="h3" className="legend-title">
        Terrestrial fibre status
      </Typography>
      <List className="list">
        {renderListItem("live", isMozambique ? "Live" : "Operational")}
        {renderListItem("under-construction", "Under construction")}
      </List>
    </Box>
  );
};

export default FibreOpticLines;
