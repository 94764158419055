import { Box } from "@mui/system";

const EarthquakesMagnitude = () => {
  return (
    <Box className="legend-icon-wrapper legend-item earhquakes-magnigutude">
      <span className="square" />
      Earthquakes Magnitude
    </Box>
  );
};

export default EarthquakesMagnitude;
