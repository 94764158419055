import React, { useContext } from "react";
import Control from "react-leaflet-custom-control";
import Legend from "../Legends/Indicators/Legend";
import { POPULATION } from "../../../utils/strings";
import {
  grossIncomeColors,
  indicator2020Colors,
  indicator2030Colors,
  populationColors,
} from "../../../utils/tiles";
import { Context } from "../../../GlobalContext";

const GROSS_INCOME_INDICATOR = "gni";

const IndicatorLegends = ({ indicatorLegendRanges }) => {
  const { indicatorData, isOpenedDrawer } = useContext(Context);
  const { year, indicator, affordabilityCategory } = indicatorData;
  const indicatorLegendColor = year === "2020" ? indicator2020Colors : indicator2030Colors;

  const isPopulation = indicator?.includes(POPULATION);
  const isGrossIncome = indicator?.includes(GROSS_INCOME_INDICATOR);

  const legendLabel = indicator?.includes("users") ? "Users" : indicator?.includes("afford") ? "Affordability" : "Subscriptions";

  const renderIndicatorLegend = (title, colors, name = "") => {
    return (
      <Legend
        ranges={indicatorLegendRanges}
        title={`${title} - year ${year}`}
        colors={colors}
        name={affordabilityCategory ? "affordability" : name}
      />
    );
  };

  const renderLegends = () => {
    if (!year || !indicator || isOpenedDrawer) return null;

    if (!isGrossIncome && !isPopulation) {
      return renderIndicatorLegend(legendLabel, indicatorLegendColor);
    }

    if (isGrossIncome) {
      return renderIndicatorLegend("Gross income", grossIncomeColors);
    }

    if (isPopulation) {
      return renderIndicatorLegend("Population", populationColors, POPULATION);
    }
  };

  return (
    <Control position="bottomright" prepend={true}>
      {renderLegends()}
    </Control>
  );
};

export default IndicatorLegends;
