import { useContext } from "react";

import {
  IconButton,
  Link,
  List,
  ListItem,
  Modal,
  Typography,
  Box,
  Backdrop,
  Fade,
} from "@mui/material";
import {
  CloseOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@mui/icons-material";

import Image from "rc-image";

import "rc-image/assets/index.css";
import "./about.scss";

import { Context } from "../../GlobalContext";
import { aboutLinks, broadbandsDescription } from "../../utils/misc";
import downscalingImage from "../../assets/downscaling-final.png";

const About = () => {
  const { isShownModal, setIsShownModal } = useContext(Context);
  return (
    <Modal
      open={isShownModal}
      onClose={() => setIsShownModal(false)}
      aria-labelledby="modal-container"
      className="about-modal-wrapper"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 250,
        },
      }}
    >
      <Fade in={isShownModal}>
        <Box className="modal-contents-wrapper about">
          <Box className="modal-header">
            <Typography className="about-title" component="h2">
              About
            </Typography>

            <IconButton
              className="close-button"
              aria-label="close"
              size="medium"
              onClick={() => setIsShownModal(false)}
            >
              <CloseOutlined fontSize="inherit" />
            </IconButton>
          </Box>

          <Box className="about-main-contents">
            <Box className="main-contents-wrapper">
              <article className="about-article">
                <Typography className="about-paragraph" component="p">
                  This is a demand broadband mapping initiative that provides
                  broadband- related indicators at subnational level by applying
                  a downscaling approach based on ancillary spatial information.
                  The indicators outline the internet demand in the urban and
                  rural areas, including the internet speed and the digital
                  literacy indirectly through the number of internet users,
                  aimed at understanding present and future internet demand.
                  Depending on the availability of the data in the different
                  countries covered by the tool, the indicators are displayed
                  following different disaggregation levels: females vs males;
                  adults (&gt;24 years) vs young (15-24 years); rural vs urban
                  areas. Urban areas are defined as urban centres, dense urban
                  clusters, semi-dense urban clusters and suburban or peri-urban
                  areas, whereas rural areas comprise rural clusters, low
                  density rural areas and very low density rural areas.
                </Typography>

                <Image
                  preview={{
                    mask: "Open preview",
                    icons: {
                      zoomOut: <ZoomOutOutlined />,
                      zoomIn: <ZoomInOutlined />,
                      rotateLeft: <RotateLeftOutlined />,
                      rotateRight: <RotateRightOutlined />,
                      close: <CloseOutlined />,
                    },
                  }}
                  src="image-preview"
                  alt="downscaling infographic"
                  loading="lazy"
                  fallback={downscalingImage}
                  width={"100%"}
                />

                <Typography className="about-paragraph" component="p">
                  Below it is provided a description of the particular
                  indicators that can be inspected through the application. With
                  the exception of “Gross income”, all other indicators are
                  provided as % of the total population at each particular
                  administrative unit (i.e., province or district) in 2020 or
                  2030, respectively:
                </Typography>

                <List className="broadband-list">
                  {broadbandsDescription.map((description, index) => (
                    <ListItem key={index} className="broadband-list-item">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                      />
                    </ListItem>
                  ))}
                </List>

                <Typography className="about-paragraph" component="p">
                  <i>
                    The indicators displayed in the application have been
                    derived from data available in the{" "}
                    <a
                      href="https://www.itu.int/en/ITU-D/Statistics/Pages/stat/default.aspx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ITU
                    </a>{" "}
                    Database, in the{" "}
                    <a
                      href="https://data.worldbank.org/indicator/NY.GNP.MKTP.CD?view=chart"
                      target="_blank"
                      rel="noreferrer"
                    >
                      WorldBank Data Portal
                    </a>{" "}
                    and from{" "}
                    <a
                      href="https://www.cable.co.uk/broadband/pricing/worldwide-comparison"
                      target="_blank"
                      rel="noreferrer"
                    >
                      cable.co.uk
                    </a>{" "}
                    website after applying a downscaling approach supported by
                    different open ancillary datasets (
                    <a
                      href="https://afterfibre.nsrc.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      AfTerFibre
                    </a>
                    ,{" "}
                    <a
                      href="https://ghsl.jrc.ec.europa.eu/datasets.php#inline-nav-ghs_pop2019"
                      target="_blank"
                      rel="noreferrer"
                    >
                      GHSL
                    </a>
                    ,{" "}
                    <a
                      href="https://ghsl.jrc.ec.europa.eu/ghs_smod2022.php"
                      target="_blank"
                      rel="noreferrer"
                    >
                      GHS-SMOD
                    </a>
                    ,{" "}
                    <a
                      href="https://location.services.mozilla.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      MLS
                    </a>
                    ,{" "}
                    <a
                      href="http://opencellid.org/downloads"
                      target="_blank"
                      rel="noreferrer"
                    >
                      OpenCellID
                    </a>
                    ,{" "}
                    <a
                      href="https://www.ookla.com/ookla-for-good/open-data"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Ookla
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.worldpop.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      WorldPop
                    </a>
                    ).
                  </i>
                </Typography>
              </article>

              {/* Links list  */}
              <List className="link-list">
                {aboutLinks.map((link, index) => (
                  <ListItem key={index} className="link-list-item">
                    <Link href={link} target="_blank">
                      {link}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default About;
