import { DRC, baseUrl } from "./strings";

export const getPointBaseLayer = (layer) => {
  return layer?.geometry?.type?.toLowerCase() === "point";
};

export const fetchData = async (url, setMessage) => {
  try {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (error) {
    setMessage("An error occurs. Could you check your network, please?");
  }
};

export const checkExistingData = async (
  countryName,
  setMessage,
  setItuDataList
) => {
  const selectedCountry = countryName === DRC ? "drc" : countryName;

  const data = await fetchData(`${baseUrl}:8888/api/existing_itu_data?country_name=${selectedCountry}`, setMessage);

  setItuDataList(data);
};
