import { memo, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import L from "leaflet";
import { renderToString } from "react-dom/server";
import { Pane, GeoJSON } from "react-leaflet";
import { POPULATION, getLayer, titleCase } from "../../../utils/strings";
import { getFixedIndicator } from "../../../utils/indicators";
import {
  controlLayer,
  getTileColors,
  grossIncomeColors,
  indicator2020Colors,
  indicator2030Colors,
  populationColors,
} from "../../../utils/tiles";
import { Context } from "../../../GlobalContext";
import { REPORT } from "../../../utils/path";

const FIXED_BROADBAND_INDICATOR = "fixed";
const GROSS_INCOME_INDICATOR = "gni";

const Indicators = memo(({ indicatorPerYear, indicatorLegendRanges, currentData }) => {
    const { pathname } = useLocation();
    const { map, darkMode, affordabilitySelection, indicatorData } = useContext(Context);
    const { year, layerLevel, urbanization } = indicatorData;
    const [tooltipContent, setTooltipContent] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ lat: 0, lng: 0 });
    const data = currentData?.data;
    const isPopulation = indicatorPerYear?.includes(POPULATION);
    const isGrossIncome = indicatorPerYear?.includes(GROSS_INCOME_INDICATOR);

    const fixedbroadbandsPerYear = getFixedIndicator({ layerLevel, year, category: urbanization });

    const selectedFixedBroadBand = fixedbroadbandsPerYear.find(
      (broadband) =>
        indicatorPerYear === broadband.value ||
        indicatorPerYear === broadband.subValue
    );

    // Legend ranges and colors
    const broadbandColorRanges = indicatorPerYear.includes("2020") ? indicator2020Colors : indicator2030Colors;

    // Layer styles and utilitie
    const getIndicatorStyle = (color) => {
      return {
        fillColor: color || (isPopulation || isGrossIncome ? "#FFF" : "#FFFFD9"),
        weight: 1,
        color: "gray",
        opacity: 1,
        fillOpacity: darkMode ? 0.9 : 0.8,
      };
    };

    // Get the unit of each type of indicator
    const getIndicatorUnit = (value) => {
      const valueString = value.toString();
      if (isGrossIncome || indicatorPerYear.includes("income")) {
        return " US$";
      } else if (isPopulation) {
        return " no. pers.";
      } else if (valueString.toLowerCase() !== "unaffordable") {
        return "%";
      } else {
        return "";
      }
    };

    // Districts and provinces controller
    const onEachCountry = useCallback((feature, layer) => {
        const { properties } = feature;

        const maxRange = indicatorLegendRanges[indicatorLegendRanges?.length - 1] || 100;

        const broadbandIndicatorColor = getTileColors({
          properties,
          ranges: indicatorLegendRanges,
          indicator: indicatorPerYear?.includes(FIXED_BROADBAND_INDICATOR) && !indicatorPerYear?.includes("afford")
            ? indicatorPerYear === selectedFixedBroadBand?.subValue
              ? selectedFixedBroadBand?.subValue
              : selectedFixedBroadBand?.value
            : indicatorPerYear,
          colors: broadbandColorRanges,
          maxValue: maxRange,
        });

        const otherIndicatorColor = getTileColors({
          properties,
          indicator: indicatorPerYear,
          colors: isPopulation ? populationColors : grossIncomeColors,
          ranges: indicatorLegendRanges,
          maxValue: maxRange,
        });

        const color = isPopulation || isGrossIncome ? otherIndicatorColor : broadbandIndicatorColor;

        const layerStyle = getIndicatorStyle(color);

        const layerName = getLayer({
          layerLevel,
          param1: properties.adm2_pt,
          param2: properties.adm1_pt,
        });

        const affordabilityValue =  properties[indicatorPerYear] > 100 ? "unaffordable" : Number(properties[indicatorPerYear]);

        const valuePerData = affordabilitySelection.length > 0 ? affordabilityValue : properties[indicatorPerYear];

        const tileValue = valuePerData !== "unaffordable" ? Number(valuePerData) : valuePerData;

        const value = typeof tileValue === "number" ? parseFloat(tileValue?.toFixed(3) || 0) : titleCase(tileValue);

        const populationValue = Math.round(tileValue) || 0;

        const indicatorValue = isPopulation ? populationValue : value;

        const indicatorUnit = getIndicatorUnit(value);

        layer.setStyle(layerStyle);

        const formattedValue =
          affordabilitySelection.length > 0
            ? Boolean(indicatorValue)
              ? `${indicatorValue}${indicatorUnit}`
              : "No Data"
            : `${indicatorValue || 0}${indicatorUnit}`;

        const tooltipContentJSX = (
          <div>
            {layerName}:{" "}
            <strong>{formattedValue}</strong>
          </div>
        );
        const tooltipContentString = renderToString(tooltipContentJSX);

        controlLayer({ layer, layerStyle, tooltipContentString, setTooltipContent, setTooltipPosition });
      },
      // eslint-disable-next-line
      [
        indicatorLegendRanges,
        indicatorPerYear,
        selectedFixedBroadBand,
        broadbandColorRanges,
        populationColors,
        grossIncomeColors,
        layerLevel,
      ]
    );

    // Using customized tooltip so that the tooltip is always above the layers
    const tooltip = L.tooltip({
      permanent: true,
      className: "indicator-tooltip",
      opacity: 1,
    });

    useEffect(() => {
      if (tooltipContent) {
        const latLng = [tooltipPosition.lat, tooltipPosition.lng];
        tooltip.setContent(tooltipContent);
        tooltip.setLatLng(latLng).addTo(map);
      } else {
        map?.removeLayer(tooltip);
      }
      return () => map?.removeLayer(tooltip);
    }, [map, tooltipContent, tooltipPosition, tooltip]);

    return (
      <Pane name="indicator">
        {data && pathname !== REPORT && (
          <GeoJSON
            key={JSON.stringify({
              indicatorPerYear,
              darkMode,
              currentData: data?.features?.length,
            })}
            data={data}
            onEachFeature={onEachCountry}
          />
        )}
      </Pane>
    );
  }
);

export default Indicators;