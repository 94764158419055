import pako from "pako";

export const getFixedIndicator = ({ layerLevel, year, category }) => {
  const data = [
    {
      value: `${layerLevel}_fixed_2_10_${year}`,
      subValue: `${layerLevel}_${category}${year}.fixed_2_10_${year}`,
    },
    {
      value: `${layerLevel}_fixed_above10_${year}`,
      subValue: `${layerLevel}_${category}${year}.fixed_above10_${year}`,
    },
    {
      value: `${layerLevel}_fixed_256_2_${year}`,
      subValue: `${layerLevel}_${category}${year}.fixed_256_2_${year}`,
    },
    {
      value: `${layerLevel}_fixed${year}`,
      subValue: `${layerLevel}_${category}${year}.fixed_${year}`,
    },
  ];

  return data;
};

export const getOtherIndicator = ({ layerLevel, year, category }) => {
  const subCategory = `${layerLevel}_${category}${year}`;
  const data = [
    {
      value: `${layerLevel}_mobile${year}`,
      subValue: `${subCategory}.mobile_${year}`,
    },
    {
      value: `${layerLevel}_users${year}`,
      subValue: `${subCategory}.users_${year}`,
    },
    {
      value: `${layerLevel}_gni${year}`,
      subValue: `${subCategory}.gni_${year}`,
    },
  ];
  return data;
};

export const getSelectedValue = ({
  selectedValue,
  setSelectedValue,
  value,
}) => {
  if (selectedValue.includes(value)) {
    const arrOfValues = selectedValue.filter((item) => item !== value);
    setSelectedValue(arrOfValues);
  } else {
    setSelectedValue([value]);
  }
};

export const getIndicatorPerYear = ({
  year,
  layerLevel,
  selectedIndicator,
}) => {
  let updatedIndicator = selectedIndicator;

  if (year === "2020" && !selectedIndicator?.includes("2020")) {
    updatedIndicator = updatedIndicator?.replaceAll("2030", "2020");
  } else if (year === "2030" && !selectedIndicator?.includes("2030")) {
    updatedIndicator = updatedIndicator?.replaceAll("2020", "2030");
  }

  if (layerLevel === "province") {
    updatedIndicator = updatedIndicator?.replaceAll("district", "province");
  } else if (layerLevel === "district") {
    updatedIndicator = updatedIndicator?.replaceAll("province", "district");
  }

  updatedIndicator = updatedIndicator?.replaceAll("year", year);

  return updatedIndicator;
};

export const getIndicator = ({ layerLevel, indicator }) => {
  if (layerLevel === "district") {
    if (!indicator?.includes("district")) {
      return indicator?.replace("province", "district");
    } else {
      return indicator;
    }
  } else {
    if (!indicator?.includes("province")) {
      return indicator?.replace("district", "province");
    } else {
      return indicator;
    }
  }
};

export const getIndicatorData = async (
  data,
  setData,
  setLoading,
  setMessage
) => {
  setLoading(true);

  try {
    const fetchFiles = async () => {
      const response = await fetch(data);
      const arrayBuffer = await response.arrayBuffer();
      const inflated = pako.inflate(arrayBuffer, { to: "string" });
      return JSON.parse(inflated);
    };

    const broadbandData = await fetchFiles();

    setData(broadbandData);
  } catch (error) {
    setMessage("Something went wrong");
  }

  setLoading(false);
};
