import { Box } from "@mui/material";

const PointBasedLayer = ({ icon, name, value }) => {
  return (
    <Box className="legend-icon-wrapper legend-item">
      <img className="legend-icon" src={icon} alt={value} />
      <span>{name}</span>
    </Box>
  );
};

export default PointBasedLayer;
