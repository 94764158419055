import React, { useState, useMemo, useContext } from "react";
import { Box, Link, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import Control from "react-leaflet-custom-control";
import datasetInfo from "../../../../utils/datasetInfo.json";
import {
  CELL_TOWERS,
  EDUCATION,
  ELECTRICITY_GRID,
  FIBRE_OPTIC_LINES,
  HEALTHCARE,
  NATURAL_HAZARD,
  ROAD_NETWORK,
  SIGNIFICANT_EARTHQUAKES,
  SIGNIFICANT_VOLCANIC_ERUPTION,
  TSUNAMI_EVENT,
} from "../../../../utils/strings";
import "./background-layer-info.scss";
import PanelHeader from "../../../PanelHeader/PanelHeader";
import OpenPanelButton from "../../../OpenPanelButton/OpenPanelButton";
import { Context } from "../../../../GlobalContext";

const BackgroundLayerInfo = ({
  selectedDatasets,
  isShownPopulationInfo,
  infoContainerRef,
}) => {
  const { countryName } = useContext(Context);
  const screenWidth = useMemo(() => window.innerWidth, []);
  const [isOpenedPanel, setIsOpenedPanel] = useState(screenWidth > 795);
  const panelClassName =
    "leaflet-control-layers leaflet-control-layers-expanded leaflet-control custom-controller-wrapper bg-layers-info";

  const infoPerCountry = useMemo(
    () =>
      datasetInfo.find((data) => data.country.toLowerCase() === countryName),
    [countryName]
  );

  const countriesToLowerCase = datasetInfo?.map(({ country }) =>
    country?.toLowerCase()
  );

  const hasDatasets = (key) => selectedDatasets.includes(key);

  const isEducation = hasDatasets(EDUCATION);
  const isHealthcare = hasDatasets(HEALTHCARE);
  const isCellTowers = hasDatasets(CELL_TOWERS);
  const isFibreOptics = hasDatasets(FIBRE_OPTIC_LINES);
  const isElectricityGrid = hasDatasets(ELECTRICITY_GRID);
  const isRoadNetwork = hasDatasets(ROAD_NETWORK);

  const isShownInfo = useMemo(
    () =>
      countryName &&
      countriesToLowerCase?.includes(countryName) &&
      (isShownPopulationInfo ||
        selectedDatasets.some((dataset) =>
          [
            NATURAL_HAZARD,
            CELL_TOWERS,
            FIBRE_OPTIC_LINES,
            ELECTRICITY_GRID,
            ROAD_NETWORK,
            EDUCATION,
            HEALTHCARE,
            SIGNIFICANT_VOLCANIC_ERUPTION,
            SIGNIFICANT_EARTHQUAKES,
            TSUNAMI_EVENT,
          ].includes(dataset)
        )),
    [selectedDatasets, isShownPopulationInfo, countryName, countriesToLowerCase]
  );

  const titleProps = {
    component: "h3",
    className: "bg-layers-category",
  };

  const paragraphProps = {
    component: "p",
    className: "bg-layers-paragraph",
  };

  const renderInfo = (property) => {
    return (
      <article className="bg-layers-article">
        <Typography
          {...paragraphProps}
          dangerouslySetInnerHTML={{
            __html: infoPerCountry?.[property],
          }}
        />
      </article>
    );
  };

  return (
    <Control position="topleft">
      {isShownInfo && (
        <Box
          className={`${panelClassName} ${
            isOpenedPanel ? "opened-info" : "closed-info"
          }`}
        >
          {isOpenedPanel && (
            <PanelHeader
              title="Data sources of background layers"
              setIsOpenedPanel={setIsOpenedPanel}
            />
          )}
          <Box className="bg-layers-wrapper" ref={infoContainerRef}>
            {!isOpenedPanel && (
              <OpenPanelButton
                title="Data sources"
                placement="right"
                Icon={InfoOutlined}
                setIsOpenedPanel={() => setIsOpenedPanel(true)}
              />
            )}

            {isEducation || isHealthcare ? (
              <section className="info-section">
                <Typography {...titleProps}>Relevant facilities</Typography>
                {renderInfo("relevantFacilities")}
              </section>
            ) : null}

            {isCellTowers || isFibreOptics ? (
              <section className="info-section">
                <Typography {...titleProps}>
                  Broadband Infrastructures
                </Typography>
                {isCellTowers && (
                  <article className="ancillary-dataset-article">
                    <Typography {...paragraphProps}>
                      <b>Cell towers: </b>positions of cell towers sourced by{" "}
                      <a
                        href="https://location.services.mozilla.com"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Mozilla Location Service (MLS)
                      </a>{" "}
                      and{" "}
                      <a
                        href="http://opencellid.org/downloads"
                        rel="noreferrer"
                        target="_blank"
                        className="info-link"
                      >
                        OpenCellID
                      </a>{" "}
                      open data services.
                    </Typography>
                  </article>
                )}

                {isFibreOptics && renderInfo("fibreOptic")}
              </section>
            ) : null}

            {isElectricityGrid || isRoadNetwork ? (
              <section className="info-section">
                <Typography {...titleProps}>Other infrastructures</Typography>
                {isElectricityGrid && renderInfo("electricityGrid")}

                {isRoadNetwork && renderInfo("roadNetwork")}
              </section>
            ) : null}

            {isShownPopulationInfo && (
              <section className="info-section">
                <Typography {...titleProps}>Population</Typography>
                <article className="bg-layers-article">
                  <Typography {...paragraphProps}>
                    Residential population estimates in 2020 and 2030 derived
                    from aggregation of the data from the{" "}
                    <Link
                      href="https://ghsl.jrc.ec.europa.eu/datasets.php#inline-nav-ghs_pop2019"
                      target="_blank"
                      rel="noreferrer"
                      className="info-link"
                    >
                      Global Human Settlement Layer (GHSL; 100 m resolution)
                    </Link>{" "}
                    at the specified administrative unit (i.e., district or
                    region).
                    <br />
                  </Typography>
                </article>
              </section>
            )}

            {hasDatasets(NATURAL_HAZARD) ||
            hasDatasets(TSUNAMI_EVENT) ||
            hasDatasets(SIGNIFICANT_VOLCANIC_ERUPTION) ||
            hasDatasets(SIGNIFICANT_EARTHQUAKES) ? (
              <section className="info-section">
                <Typography {...titleProps}>
                  Data sources of background layers for natural hazard at the
                  global level
                </Typography>

                {hasDatasets(NATURAL_HAZARD) && (
                  <Typography {...paragraphProps}>
                    <span className="link-wrapper">
                      <b>Flood:</b>
                      <Link
                        href="http://global-flood-database.cloudtostreet.info/"
                        target="_blank"
                        rel="noreferrer"
                        className="info-link"
                      >
                        http://global-flood-database.cloudtostreet.info/
                      </Link>
                    </span>
                  </Typography>
                )}

                {hasDatasets(TSUNAMI_EVENT) && (
                  <Typography {...paragraphProps}>
                    <b>Tsunami Source Events:</b> A tsunami is a series of waves
                    generated by an impulsive disturbance in the ocean or in a
                    small, connected body of water. These waves sometimes
                    inflict severe damage to property and pose a threat to life
                    in coastal communities. This data set has been derived from
                    Historical Tsunami Source Event Data compiled by the
                    National Centers for Environmental Information (NCEI). The
                    Tsunami Source Event database is a listing of historical
                    tsunami source event locations that range in date from 2000
                    B.C. to the present. It is related to the Tsunami
                    Observations (Runups) database which contains information on
                    the locations where tsunami effects occurred.
                    <br />
                    <span className="link-wrapper">
                      <span> Source: </span>
                      <Link
                        href="https://ngdc.noaa.gov/hazard/tsu.shtml"
                        target="_blank"
                        rel="noreferrer"
                        className="info-link"
                      >
                        https://ngdc.noaa.gov/hazard/tsu.shtml
                      </Link>
                    </span>
                    Metadata of the GeoJSON File:
                    <br />
                    Legend: The legend of the events categorized by period
                    (i.e., 2000 BC-1499, 1500-1799, 1900- 1949, 1800-1899 and
                    1950-present).
                    <br />
                    Date: The date the event was registered, in the format
                    Year/Month/Day.
                    <br />
                    Site: The location of the event, including the country and
                    specific site.
                    <br />
                  </Typography>
                )}

                {hasDatasets(SIGNIFICANT_VOLCANIC_ERUPTION) && (
                  <Typography {...paragraphProps}>
                    <b>Significant Earthquakes:</b> Earthquakes are the most
                    common cause of tsunamis. The Significant Earthquake Data
                    set, also maintained by the National Centers for
                    Environmental Information (NCEI), chronicles historical
                    earthquakes worldwide from 2150 B.C. to the present.
                    <br />
                    <span className="link-wrapper">
                      <span>Source: </span>
                      <Link
                        href="https://ngdc.noaa.gov/hazard/earthqk.shtml"
                        target="_blank"
                        rel="noreferrer"
                        className="info-link"
                      >
                        https://ngdc.noaa.gov/hazard/earthqk.shtml
                      </Link>
                    </span>
                    Metadata of the GeoJSON File:
                    <br />
                    Legend: The legend of the events categorized by period
                    (i.e., 3000 BC-1499, 1500-1799, 1800- 1899, 1900-1949, and
                    1950-present).
                    <br />
                    Date: The date the earthquake occurred, in the format
                    Year/Month/Day.
                    <br />
                    Site: The location of the earthquake, including the country
                    and specific site.
                    <br />
                  </Typography>
                )}

                {hasDatasets(SIGNIFICANT_EARTHQUAKES) && (
                  <Typography {...paragraphProps}>
                    <b>Significant Volcanic Eruptions:</b> Volcanic eruptions
                    can also trigger tsunamis, particularly when they involve
                    large-scale displacement of water or pyroclastic flows. The
                    NGDC Significant Volcanic Eruptions database, maintained by
                    the National Centers for Environmental Information (NCEI),
                    provides a comprehensive record of volcanic eruptions from
                    4350 B.C. to the present.
                    <br />
                    <span className="link-wrapper">
                      <span>Source: </span>
                      <Link
                        href="https://ngdc.noaa.gov/hazard/volcano.shtml"
                        target="_blank"
                        rel="noreferrer"
                        className="info-link"
                      >
                        https://ngdc.noaa.gov/hazard/volcano.shtml
                      </Link>
                    </span>
                    Metadata of the GeoJSON File:
                    <br />
                    Legend: The legend of the events categorized by period
                    (i.e., 5000 BC-1499, 1500-1799, 1800- 1899, 1900-1949,
                    1950-present).
                    <br />
                    Date: The date the eruption occurred, in the format
                    Site:Year.
                    <br />
                    Site: The location of the eruption, including the country
                    and specific site.
                    <br />
                  </Typography>
                )}
              </section>
            ) : null}
          </Box>
        </Box>
      )}
    </Control>
  );
};

export default BackgroundLayerInfo;
