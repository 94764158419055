export const populationColors = [
  "#FFF",
  "#BDBDBD",
  "#808080",
  "#424242",
  "#050505",
];

export const grossIncomeColors = [
  "#F7FCF5",
  "#C9EAC2",
  "#7BC77C",
  "#52934B",
  "#24451B",
];

export const indicator2020Colors = [
  "#FFFFD9",
  "#EDF8B1",
  "#C7E9B4",
  "#7FCDBB",
  "#41B6C4",
  "#1D91C0",
  "#225EA8",
  "#253494",
  "#081D58",
];

export const indicator2030Colors = [
  "#FFFFCC",
  "#FFEDA0",
  "#FED976",
  "#FEB24C",
  "#FD8D3C",
  "#FC4E2A",
  "#E31A1C",
  "#BD0026",
  "#800026",
];

export const darkModeColor = [
  "#FFF",
  "#C4DAF3",
  "#8AB6E8",
  "#5294DD",
  "#1D76D2",
];

export const getTileColors = ({
  properties,
  indicator,
  colors,
  ranges,
  maxValue,
}) => {
  const value = Number(properties[indicator]);
  for (let i = 0; colors.length > i; i++) {
    if (value >= ranges[i] && value <= ranges[i + 1]) {
      return colors[i];
    }
  }

  if (value >= maxValue) {
    return colors[ranges?.length - 2];
  }
};

export const controlLayer = ({ layer, layerStyle, tooltipContentString, setTooltipContent, setTooltipPosition }) => {
  layer.on({
    mousemove: ({ latlng }) => {
      setTooltipPosition(latlng);
      layer.bringToFront();
      layer.setStyle({ ...layerStyle, color: "#FFF", weight: 3 });
      setTooltipContent(tooltipContentString);
    },
    mouseout: () => {
      setTooltipContent(null);
      layer.setStyle(layerStyle);
    },
  });
};
