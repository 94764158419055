import React, { useRef, useState, useEffect, useContext } from "react";
import Control from "react-leaflet-custom-control";
import {
  Badge,
  Box,
  Chip,
  Divider,
  FormControl,
  RadioGroup,
} from "@mui/material";
import { DragIndicatorOutlined } from "@mui/icons-material";

import "./indicator.scss";

// Utilities
import { layerlevels, years, indicatorPerLayer, affordabilityPlan } from "../../../utils/misc.js";
import { baseUrl, className, DRC, POPULATION } from "../../../utils/strings.js";
import { getFixedIndicator, getIndicatorPerYear, getOtherIndicator } from "../../../utils/indicators.js";
import { fetchData } from "../../../utils/api.js";

// Components
import ControllerContainer from "../../PanelContainer/PanelContainer.jsx";
import PanelHeader from "../../PanelHeader/PanelHeader.jsx";
import OpenPanelButton from "../../OpenPanelButton/OpenPanelButton.jsx";
import RadioButton from "../../Forms/RadioButton/RadioButton.jsx";
import { Context } from "../../../GlobalContext.jsx";
import IndicatorChildren from "./IndicatorChildren.jsx";
import IndicatorLegends from "./IndicatorLegends.jsx";
import { default as IndicatorLayers } from "../../Layers/Indicators/Indicators.jsx";
import DateAdminForm from "../../Forms/DateAdmin/DateAdminForm.jsx";

// Strings
const buttonName = { INDICATOR: "indicator" };
const FIXED_BROADBAND_INDICATOR = "fixed";
const GROSS_INCOME_INDICATOR = "gni";

const { INDICATOR } = buttonName;

const { SUB_SECTION, CONTROLLER_TITLE, FORM_CONTROL_LABEL, FIXED_BROADBAND } = className;

const replacements = [
  "_male",
  "_female",
  "_urban",
  "_rural",
  "_young",
  "_adult",
];

const affordLegends = [0, 25, 50, 75, 100, 101];

const Indicators = () => {
  const {
    ituDataList,
    indicatorData,
    countryName,
    setIsShownPopulationInfo,
    setMessage,
    setIndicatorData,
    isOpenedDrawer,
    existingData,
    setExistingData,
    legends,
    loading,
    setLoading,
    affordabilitySelection,
    setAffordabilitySelection,
  } = useContext(Context);

  const containerRef = useRef();
  const screenWidth = window.innerWidth;
  const [scrollPosition, setScrollPosition] = useState(containerRef?.current?.scrollTop || 0);
  const [isOpenedPanel, setIsOpenedPanel] = useState(screenWidth > 795);
  const [selectedUrbanization, setSelectedUrbanization] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedAge, setSelectedAge] = useState([]);

  const {
    layerLevel,
    year,
    indicator,
    urbanization,
    gender,
    age,
    affordability,
    affordabilityCategory,
  } = indicatorData;

  const newCountry = countryName === DRC ? "drc" : countryName;

  const legendsProperties = Object.keys(legends || []);

  const isShownIndicator = ituDataList?.includes(`${newCountry}_itu_${layerLevel}`);

  // Raw data to display
  const currentData = existingData.find(({ dataName }) => {
    if (affordability) {
      const indicatorValue = `${newCountry}-${layerLevel}-${affordabilityCategory}-affordability`;
      return dataName === indicatorValue;
    } else {
      return dataName === `${newCountry}-${layerLevel}`;
    }
  });

  const isButtonActivated = layerLevel || year || indicator;

  const indicatorPerYear = getIndicatorPerYear({
    layerLevel,
    year,
    selectedIndicator: indicator,
  });

  const getLegendRanges = () => {
    if (legends && legends[indicatorPerYear]) {
      const { min, max } = legends[indicatorPerYear];
      return [...new Set([min, max].flat())].map((item) => Number(item));
    }
    return [];
  };

  const indicatorLegendRanges = affordabilitySelection.length > 0 ? affordLegends : getLegendRanges();

  const getFixedbroadBand = (data) =>
    data?.includes(FIXED_BROADBAND_INDICATOR) &&
    !data?.includes("itufixed") &&
    !data?.includes("cablefixed");

  const formIndicators = indicatorPerLayer.map((indicator) => ({
    ...indicator,
    value: `${layerLevel}${indicator.value}${year}`,
  }));

  const formIndicatorPerYear = formIndicators.filter(({ value }) => value.includes(year));

  // Indicators utilities
  const otherBroadbands = formIndicatorPerYear.filter(
    (indicator) =>
      !getFixedbroadBand(indicator.value) &&
      !indicator.value.includes(GROSS_INCOME_INDICATOR) &&
      !indicator.value.includes(POPULATION) &&
      !indicator.value.includes("afford.itu")
  );

  const grossIncome = formIndicatorPerYear.filter(({ value }) => value.includes(GROSS_INCOME_INDICATOR)
  );

  // Get population data
  const population = formIndicatorPerYear.filter(({ value }) => value.includes(POPULATION));

  // Get fixed broadband data
  const fixedBroadbands = formIndicatorPerYear.filter(({ value }) => getFixedbroadBand(value));

  const remoteFixedIndicator =
    legends &&
    layerLevel &&
    year &&
    fixedBroadbands.filter(({ value }) => legendsProperties?.includes(value));

  const fixedIndicator = remoteFixedIndicator || [];

  const isShownFixedBroadband = getFixedbroadBand(indicator);

  const getSelectedCategory = () => {
    if (selectedUrbanization.length > 0) {
      return urbanization;
    };

    if (selectedGender.length > 0) {
      return gender;
    };

    if (selectedAge.length > 0) {
      return age;
    };
  };

  const category = getSelectedCategory();

  const indicatorProps = { year, layerLevel, category };

  const getCategory = () => {
    const otherIndicators = getOtherIndicator(indicatorProps);
    return otherIndicators;
  };

  const fixedBroadbandPerYear = getFixedIndicator(indicatorProps);

  const getFixedIndicatorSubValue = (value) => {
    const fixedBroadband = fixedBroadbandPerYear.find(({ subValue }) => subValue === indicatorPerYear);
    return value === fixedBroadband?.value;
  };

  const onSelectMainIndicator = (value) => {
    setIndicatorData({
      ...indicatorData,
      indicator: value,
      urbanization: "",
      gender: "",
      age: "",
      affordability: "",
      affordabilityCategory: "",
    });

    setSelectedUrbanization([]);
    setSelectedAge([]);
    setSelectedGender([]);
    setAffordabilitySelection([]);

    getScrollPosition();
  };

  const getAffordSubsection = (data) => {
    const selectedData = data?.subsection.filter(({ value }) => value === affordability);
    return selectedData.length > 0;
  };

  const checkAffordabilitySelection = (value, data) => {
    const selectedData = getAffordSubsection(data);
    return (
      affordability?.includes(value) ||
      affordabilitySelection?.includes(value) ||
      selectedData
    );
  };

  const getScrollPosition = () =>
    setScrollPosition(containerRef?.current?.scrollTop);

  const getIndicatorValue = (property, value) => {
    setIndicatorData({ ...indicatorData, [property]: value });

    if (property !== "year" && property !== "layerLevel") {
      setAffordabilitySelection([]);
    };

    getScrollPosition();
  };

  const getValue = () => {
    let value = indicatorPerYear;
    replacements.forEach((replacement) => {
      if (indicatorPerYear?.includes(replacement)) {
        value = value.replace(replacement, "_undefined");
      }
    });
    return value;
  };

  const selectedFixedIndicator = fixedBroadbandPerYear.find(({ subValue }) => subValue === getValue());

  const generateLayerKey = (country, layer, suffix = "") => {
    return `${country}_itu_${layer}${suffix ? `_${suffix}` : ""}`;
  };

  const layerLevelOptions = layerlevels.filter((layerLevel) => {
    const suffixes = [
      "",
      "non_aggregated_affordability",
      "gender_affordability",
      "rural_urban_affordability",
    ];

    return suffixes.some((suffix) => ituDataList?.includes(generateLayerKey(newCountry, layerLevel, suffix)));
  });

  const dataAllKeys = currentData?.data?.features?.map(data => Object.keys(data?.properties || {})).flat();

  const affordCategory = indicatorPerYear.includes("non.aggregated") ? "non.aggregated" : category;

  const dataKeys = (targetYear) => [...new Set(dataAllKeys)].filter(item => item.includes(targetYear) && item.includes(affordCategory) && item.includes(affordability));

  const yearOptions = years.filter((year) => {
    const isYear2020 = legendsProperties?.includes(indicatorPerYear?.replaceAll("2030", "2020"));
    const isYear2030 = legendsProperties?.includes(indicatorPerYear?.replaceAll("2020", "2030"));

    if (affordabilitySelection.length > 0) {
      if (year === "2020") {
        return dataKeys("2020").length > 0;
      } else if (year === "2030") {
        return dataKeys("2030").length > 0;
      }
    } else {
      if (year === "2020") {
        return isYear2020;
      } else if (year === "2030") {
        return isYear2030;
      }
    }

    return false;
  });

  const getAffordability = async (category) => {
    const data = await fetchData(
      `${baseUrl}:8888/api/affordability?country_name=${newCountry}&layer_level=${layerLevel}&category=${category}`,
      setMessage
    );

    setExistingData([
      ...existingData,
      {
        data,
        layerLevel: layerLevel,
        country: newCountry,
        dataName: `${newCountry}-${layerLevel}-${category}-affordability`,
      },
    ]);
  };

  const getAffordabilityData = async ({
    value,
    category = "non_aggregated",
    subCategory = "",
    isReset = false,
  }) => {
    const categoryValue =
      category === "non_aggregated"
        ? "non.aggregated"
        : subCategory
        ? subCategory
        : category;

    const indicatorValue =
      value === "income"
        ? `${layerLevel}_${categoryValue}_${value}_${year}`
        : `${layerLevel}_${categoryValue}_afford.${value}_${year}`;

    if (isReset) {
      await setIndicatorData({
        ...indicatorData,
        indicator: indicatorValue,
        affordability: value,
        affordabilityCategory: category,
        urbanization: "",
        gender: "",
        age: "",
      });

      setSelectedUrbanization([]);
      setSelectedAge([]);
      setSelectedGender([]);
    } else {
      await setIndicatorData({
        ...indicatorData,
        indicator: indicatorValue,
        affordability: value,
        affordabilityCategory: category,
      });
    }
  };

  const fetchAffordability = async ({ category = "non_aggregated" }) => {
    setLoading([affordability]);
    const isDataExist = existingData.find(({ dataName }) => dataName === `${newCountry}-${layerLevel}-${category}-affordability`);

    if (!isDataExist) {
      await getAffordability(category);
    }

    setLoading([]);
  };

  const getYearAdminProps = (property, value) => {
    return {
      value,
      defaultChecked: indicatorData[property] === value,
      action: (event) => {
        getIndicatorValue(property, event.target.value);
        setScrollPosition(containerRef?.current?.scrollTop);
      }
    }
  };

  const renderSubsection = (value) => {
    return (
      <IndicatorChildren
        {...{
          value,
          year,
          layerLevel,
          newCountry,
          indicatorPerYear,
          selectedUrbanization,
          setSelectedUrbanization,
          selectedGender,
          setSelectedGender,
          selectedAge,
          setSelectedAge,
          getScrollPosition,
          getAffordabilityData,
        }}
      />
    );
  };

  const renderAffordability = (data) => {
    return (
      <RadioGroup name={INDICATOR} className={SUB_SECTION}>
        {data.subsection.map(({ id, value, name }) => {
          return (
            <RadioButton
              key={id}
              label={name}
              value={value}
              loading={loading.includes(value)}
              className={FORM_CONTROL_LABEL}
              children={affordability === value && renderSubsection(value)}
              action={() => {
                getAffordabilityData({ value, category: "non_aggregated", isReset: true });
                getScrollPosition();
              }}
              isChecked={affordability === value}
            />
          );
        })}
      </RadioGroup>
    );
  };

  const renderTitle = (title) => {
    return <span className={CONTROLLER_TITLE}>{title}</span>;
  };

  useEffect(() => {
    setIsShownPopulationInfo(indicator?.includes(POPULATION));
  }, [indicator, setIsShownPopulationInfo]);

  // Scroll utilities
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollPosition;
    }
  }, [scrollPosition, indicator, indicatorPerYear, population]);

  // When there is no sub-category (rural, urban, female, male, adult and young) selected, the form will take the value of the main form.
  useEffect(() => {
    const noSelection =
      selectedUrbanization.length === 0 &&
      selectedGender.length === 0 &&
      selectedAge.length === 0;

    // Fixed indicators
    if (selectedFixedIndicator && selectedUrbanization.length === 0) {
      getIndicatorValue("indicator", selectedFixedIndicator?.value);
    }

    // Other indicators (users, mobile, gross income, and population)
    if (!isShownFixedBroadband && !indicatorPerYear?.includes(POPULATION)) {
      const otherIndicators = getCategory();

      const selectedOtherIndicator = otherIndicators.find((item) => item?.subValue === getValue());

      if (selectedOtherIndicator && noSelection) {
        getIndicatorValue("indicator", selectedOtherIndicator?.value);
      }
    }

    // Affordability
    if (affordability && noSelection) {
      getAffordabilityData({ category: "non_aggregated", value: affordability });
    }

    // eslint-disable-next-line
  }, [selectedUrbanization, selectedGender, selectedAge]);

  useEffect(() => {
    if (affordability && layerLevel && year) {
      fetchAffordability({
        category: affordabilityCategory,
        value: affordability,
        subCategory: category,
      });
    }

    // eslint-disable-next-line
  }, [layerLevel, affordability, affordabilityCategory]);

  return (
    <Control position="topright">
      <Box>
        {!isOpenedDrawer &&
          ituDataList?.length > 0 &&
          layerLevelOptions?.length > 0 && (
            <ControllerContainer
              className={`indicator-controller-wrapper 
            ${!isOpenedPanel && "closed-panel"} 
            ${
              year &&
              layerLevel &&
              !indicatorPerYear?.includes(POPULATION) &&
              "long-panel"
            }`}
            >
              <PanelHeader
                setIsOpenedPanel={setIsOpenedPanel}
                title={
                  <>
                    <span>Broadband indicators</span>
                    {isButtonActivated && (
                      <Chip
                        label="Reset"
                        variant="outlined"
                        className="reset-button chip"
                        onClick={() => {
                          setIndicatorData({
                            ...indicatorData,
                            layerLevel: "",
                            year: "",
                            indicator: "",
                            affordability: "",
                            affordabilityCategory: "",
                          });
                          setAffordabilitySelection([]);
                        }}
                      />
                    )}
                  </>
                }
              />
              <Box className={"indicator-wrapper"} ref={containerRef}>
                {!isOpenedPanel && (
                  <Badge
                    color="secondary"
                    variant="dot"
                    badgeContent={isButtonActivated ? 1 : 0}
                  >
                    <OpenPanelButton
                      title="Display indicators"
                      placement="left"
                      Icon={DragIndicatorOutlined}
                      setIsOpenedPanel={() => setIsOpenedPanel(true)}
                    />
                  </Badge>
                )}
                <FormControl className="indicator-controller">
                  <DateAdminForm
                    id="indicator"
                    name="administrative-level"
                    label="Step 1. Select an administrative level"
                    options={layerLevelOptions}
                    {...getYearAdminProps("layerLevel", layerLevel)}
                  />


                  {layerLevel && (
                    <>
                      <Divider className="divider-line" />
                      <DateAdminForm
                        id="indicator"
                        name="year"
                        label="Step 2. Select a year"
                        options={yearOptions.length > 0 ? yearOptions : years}
                        {...getYearAdminProps("year", year)}
                      />
                    </>
                  )}

                  {year && (
                    <>
                      <Divider className="divider-line" />
                      <FormControl>
                        {renderTitle("Step 3. Select a broadband or an economic indicator")}
                        <FormControl>
                          {isShownIndicator && (
                            <span className={`${CONTROLLER_TITLE} ${SUB_SECTION}`}>
                              Broadband indicators (%)
                            </span>
                          )}
                          <RadioGroup name={INDICATOR} aria-label={INDICATOR}>
                            <>
                              <Box className={SUB_SECTION}>
                                {isShownIndicator && (
                                  <>
                                    <RadioButton
                                      label="Fixed broadband by speed"
                                      value="fixed"
                                      isChecked={isShownFixedBroadband}
                                      action={() => {
                                        fixedIndicator && onSelectMainIndicator(fixedIndicator[0]?.value);
                                      }}
                                    />
                                    {/* Fixed broadband */}
                                    {isShownFixedBroadband && (
                                      <RadioGroup name={FIXED_BROADBAND} className={SUB_SECTION}>
                                        {fixedIndicator?.map(({ id, value, name }) => {
                                            const isActivated =
                                              value === indicatorPerYear ||
                                              value === selectedFixedIndicator?.value;
                                            return (
                                              <RadioButton
                                                key={id}
                                                label={name}
                                                value={value}
                                                loading={loading.includes(value)}
                                                children={renderSubsection(value)}
                                                action={(event) => {
                                                  onSelectMainIndicator(event.target.value);
                                                }}
                                                isChecked={
                                                  isActivated ||
                                                  getFixedIndicatorSubValue(value)
                                                }
                                              />
                                            );
                                          }
                                        )}
                                      </RadioGroup>
                                    )}
                                    {/* Other broadband indicators */}
                                    {otherBroadbands.map(({ id, value, name }) => {
                                        const values = ["mobile", "users"];

                                        let isShownChildren = false;

                                        values.forEach((item) => {
                                          if (indicatorPerYear?.includes(item)) {
                                            isShownChildren =
                                              !indicatorPerYear.includes("itu_datamobile") &&
                                              !indicatorPerYear.includes("itu_mobile") &&
                                              indicatorPerYear?.includes(item) &&
                                              value?.includes(item);
                                          }
                                        });

                                        return (
                                          <RadioButton
                                            key={id}
                                            loading={loading.includes(value)}
                                            label={name}
                                            value={value}
                                            action={(event) => {onSelectMainIndicator(event.target.value)}}
                                            isChecked={
                                              value === indicatorPerYear ||
                                              (isShownChildren &&
                                                !indicatorPerYear?.includes("gni") &&
                                                !indicatorPerYear?.includes("fixed") &&
                                                !indicatorPerYear?.includes("pop")
                                              )}
                                            children={renderSubsection(value)}
                                          />
                                        );
                                      }
                                    )}
                                  </>
                                )}

                                {/* Economic indicator */}
                                <Box>
                                  {renderTitle("Economic indicators")}
                                  <Box className={SUB_SECTION}>
                                    {isShownIndicator && (
                                      <>
                                        {renderTitle("Gross Income (US dollars per capita)")}
                                        <RadioButton
                                          loading={loading.includes("gni")}
                                          label="Gross income"
                                          value="gross_income"
                                          action={() => onSelectMainIndicator(grossIncome[0].value)}
                                          isChecked={
                                            grossIncome[0].value === indicatorPerYear ||
                                            indicatorPerYear?.includes("gni")
                                          }
                                          children={renderSubsection(grossIncome[0].value)}
                                        />
                                      </>
                                    )}

                                    {/* Affordability */}
                                    {ituDataList?.includes(`${newCountry}_itu_${layerLevel}_non_aggregated_affordability`) && (
                                      <>
                                        {renderTitle("Affordability (% of a person’s income)")}

                                        {/* Cable Fixed*/}
                                        <>
                                          <RadioButton
                                            label="Select the broadband plan"
                                            value={affordabilityPlan?.value}
                                            action={() => {
                                              setAffordabilitySelection([affordabilityPlan.value]);
                                              getScrollPosition();
                                            }}
                                            isChecked={checkAffordabilitySelection(affordabilityPlan.value, affordabilityPlan)}
                                          />

                                          {(affordabilitySelection?.includes(affordabilityPlan.value) ||
                                            getAffordSubsection(affordabilityPlan)) &&
                                            renderAffordability(affordabilityPlan)}
                                        </>
                                      </>
                                    )}
                                  </Box>
                                </Box>
                              </Box>

                              {/* Population */}
                              {isShownIndicator && (
                                <>
                                  {renderTitle("Step 4. Check the total population per administrative level")}
                                  <RadioButton
                                    loading={loading.includes(population[0].value)}
                                    label="Population (no. pers)"
                                    value="population"
                                    action={() => onSelectMainIndicator(population[0].value)}
                                    isChecked={population[0].value === indicatorPerYear}
                                  />
                                </>
                              )}
                            </>
                          </RadioGroup>
                        </FormControl>
                      </FormControl>
                    </>
                  )}
                </FormControl>
              </Box>
            </ControllerContainer>
          )}
      </Box>

      {/* Indicators layer */}
      {indicator && currentData && !isOpenedDrawer && (
        <IndicatorLayers {...{ indicatorPerYear, indicatorLegendRanges, currentData }} />
      )}

      {/* Legends  */}
      <IndicatorLegends {...{ indicatorLegendRanges }} />
    </Control>
  );
};


export default Indicators;