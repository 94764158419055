import React from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import { naturalHazardLegend } from "../../../../../utils/legends";

const SignificantEarthquakes = () => {
  return (
    <Box className="natural-hazard legend-item">
      <Typography component="h4" className="legend-title">
        Significant Earthquakes Effects
      </Typography>
      <List className="list">
        {naturalHazardLegend.map(({ hex, label, color }, index) => (
          <ListItem key={index} className="list-item">
            <div className="legend-icon-wrapper">
              <span
                className={`status-color circle circle-${color}`}
                style={{ backgroundColor: hex }}
              />
            </div>

            {label}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SignificantEarthquakes;
