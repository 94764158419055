import { useContext } from "react";
import { Alert, Snackbar } from "@mui/material";
import { Context } from "../../GlobalContext";

const Error = () => {
  const { message, setMessage } = useContext(Context);
  return (
    <Snackbar
      open={message.length > 0}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={() => setMessage("")}
    >
      <Alert
        className="alert"
        variant="filled"
        severity="error"
        onClose={() => setMessage("")}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Error;
