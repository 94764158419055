import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { layerlevels } from "../../../utils/misc";
import DateAdminForm from "../../../components/Forms/DateAdmin/DateAdminForm";

const Form = ({ layerLevel, setLayerLevel, adminList, selectedAdmins, setSelectedAdmins }) => {
  const handleChange = (event) => {
    const { target: { value } } = event;
    setSelectedAdmins(typeof value === "string" ? value.split(",") : value);
  };

  const handleDelete = (value) => {
    const selectedAdminCopy = [...selectedAdmins].filter((item) => item !== value);
    setSelectedAdmins(selectedAdminCopy);
  };

  const getProps = (value) => {
    return {
      value,
      id: "report",
      name: "year",
      label: "Select an administrative level",
      options: layerlevels,
      containerClassName: "select-subnational-level",
      defaultChecked: layerLevel === value,
      action: (event) => setLayerLevel(event.target.value)
    }
  };

  return (
    <Box className="form-container">
      <DateAdminForm {...getProps(layerLevel)} />

      <FormControl className="multiselect-form">
        <InputLabel id="multi-select-input">Select {layerLevel}s of interest
        <Select
          multiple
          labelId="multi-select-input"
          id="multiple-chip"
          className="multiselect-input"
          value={selectedAdmins}
          input={<OutlinedInput id="select-admin-levels" />}
          onChange={handleChange}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  className="result-chip chip"
                  onMouseDown={(event) => {
                    event.stopPropagation();
                    handleDelete(value);
                  }}
                  onDelete={() => handleDelete(value)}
                />
              ))}
            </Box>
          )}
          MenuProps={{
            PaperProps: { style: { width: 250 } },
          }}
        >
          {adminList.map((name) => (
            <MenuItem
              key={name}
              value={name}
              disabled={ selectedAdmins.length >= 5 && !selectedAdmins.includes(name)}
              className={selectedAdmins.includes(name) ? "active-multiselect-value" : ""}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        </InputLabel>
      </FormControl>
    </Box>
  );
};

export default Form;
