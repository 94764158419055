import { memo } from "react";
import { CircularProgress, FormControlLabel, Radio } from "@mui/material";
import "./radio-button.scss";
import { className } from "../../../utils/strings";

const RadioButton = memo(
  ({
    id,
    loading,
    label,
    value,
    action,
    isChecked,
    isParent = false,
    children,
  }) => {
    const { FORM_CONTROL_LABEL, TOGGLE_BUTTON } = className;

    const isDisabled = loading && !isParent;

    const formAction = !isParent ? action : () => null;

    const formProps = {
      value,
      label,
      "aria-label": label,
      disabled: isDisabled,
      className: FORM_CONTROL_LABEL,
      onChange: formAction,
    };

    const radioProps = {
      className: TOGGLE_BUTTON,
      size: "small",
    };

    return (
      <>
        <FormControlLabel
          {...formProps}
          control={
            isDisabled ? (
              <CircularProgress value={null} className="controller-loading" />
            ) : (
              <Radio id={id || value} checked={isChecked} {...radioProps} />
            )
          }
        />
        {isChecked && children}
      </>
    );
  }
);

export default RadioButton;
