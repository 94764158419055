import { memo, useContext } from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";

import "./drawer.scss";
import { Context } from "../../../GlobalContext";

// Utilities
import { className, CELL_TOWER_COUNT, DRC } from "../../../utils/strings";
import { drawerData, layerlevels, years } from "../../../utils/misc";

// Components
import PanelHeader from "../../PanelHeader/PanelHeader";
import DateAdminForm from "../../Forms/DateAdmin/DateAdminForm";
import SliderItem from "../../Forms/SliderItem/SliderItem";

const { CONTROLLER_TITLE, DIVIDER_LINE } = className;

const Drawer = memo(() => {
  const {
    countryName,
    isOpenedDrawer,
    setIsOpenedDrawer,
    setSliderData,
    sliderData,
    ituDataList,
  } = useContext(Context);

  const { year, layerLevel } = sliderData;

  const newCountry = countryName === DRC ? "drc" : countryName;

  const layerLevelOptions = layerlevels.filter((layerLevel) => {
    const isLayer = ituDataList?.includes(`${newCountry}_itu_${layerLevel}`);
    return isLayer && layerLevel;
  });

  const getProps = (property, value) => {
    return {
      value,
      containerClassName: "drawer-form-control",
      defaultChecked: sliderData[property] === value,
      action: (event) => setSliderData({ ...sliderData, [property]: event.target.value })
    }
  };

  return (
    <Paper className={`drawer ${isOpenedDrawer ? "opened-drawer" : "closed-drawer"}`}>
      <PanelHeader
        title="Broadband indicators filter"
        setIsOpenedPanel={() => setIsOpenedDrawer(false)}
      />
      <Box className="drawer-content">
        <DateAdminForm
          id="drawer"
          name="layerLevel"
          label="Step 1. Select an administrative level"
          options={layerLevelOptions}
          {...getProps("layerLevel", layerLevel)}
        />

        <Divider className={DIVIDER_LINE} />

        <DateAdminForm
          id="drawer"
          name="year"
          label="Step 2. Select a year"
          options={years}
          {...getProps("year", year)}
        />


        <Divider className={DIVIDER_LINE} />

        <Box className="slider-container">
          <Typography component="h3" className={CONTROLLER_TITLE}>
            Step 3. Move the sliders
          </Typography>
          <Typography className="note">
            Please use the sliders to filter the range of values for the
            specified indicators to be displayed in the map
          </Typography>
          {drawerData.map(({ name, value }) => {
            return (
              <SliderItem
                key={value}
                name={value === CELL_TOWER_COUNT ? name : `${name} (%)`}
                value={value}
              />
            );
          })}
        </Box>
      </Box>
    </Paper>
  );
});

export default Drawer;
