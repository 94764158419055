import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { className } from "../../utils/strings";

const CheckboxGroup = ({
  label,
  value,
  children,
  checked,
  action,
  indeterminate,
}) => {
  const { FORM_CONTROL_LABEL, TOGGLE_BUTTON } = className;

  return (
    <Box>
      <FormControlLabel
        className={FORM_CONTROL_LABEL}
        label={label}
        value={value}
        control={
          <Checkbox
            id={label}
            className={TOGGLE_BUTTON}
            checked={indeterminate}
            indeterminate={checked}
            onChange={action}
            size="small"
          />
        }
      />
      {children}
    </Box>
  );
};

export default CheckboxGroup;
