import React, { useContext } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { className, titleCase } from "../../../utils/strings";
import { urbanizationClasses, ages, genders } from "../../../utils/misc";
import {
  getFixedIndicator,
  getOtherIndicator,
  getSelectedValue,
} from "../../../utils/indicators";
import { Context } from "../../../GlobalContext";

const { SUB_SECTION, FORM_CONTROL_LABEL, TOGGLE_BUTTON, CONTROLLER_TITLE } =
  className;

const renderController = (value, selectedValue) => (
  <Checkbox
    id={value}
    size="small"
    className={TOGGLE_BUTTON}
    checked={selectedValue.includes(value)}
  />
);

const IndicatorChildren = ({
  year,
  value,
  layerLevel,
  newCountry,
  indicatorPerYear,
  selectedUrbanization,
  setSelectedUrbanization,
  selectedGender,
  setSelectedGender,
  selectedAge,
  setSelectedAge,
  getScrollPosition,
  getAffordabilityData,
}) => {
  const { legends, indicatorData, setIndicatorData, ituDataList } = useContext(Context);
  const { affordability } = indicatorData;

  const getIndicator = () => {
    if (indicatorPerYear.includes("gni")) {
      return "gni";
    } else if (indicatorPerYear.includes("users")) {
      return "users";
    } else if (
      indicatorPerYear.includes("mobile") &&
      !indicatorPerYear.includes("itu_datamobile") &&
      !indicatorPerYear.includes("itu_mobile")
    ) {
      return "mobile";
    } else {
      return "fixed";
    }
  };

  const indicator = getIndicator();

  const getIndicators = (category) => {
    const props = { layerLevel, year, category: category };
    if (indicator === "fixed") {
      return getFixedIndicator(props);
    } else {
      return getOtherIndicator(props);
    }
  };

  const getValue = (category) => {
    if (indicator === "fixed") {
      const newValue =
        value === `${layerLevel}_fixed${year}`
          ? `${layerLevel}_fixed_${year}`
          : value;

      return newValue?.replace(
        /province_|district_/g,
        `${layerLevel}_${category}${year}.`
      );
    } else {
      return `${layerLevel}_${category}${year}.${indicator}_${year}`;
    }
  };

  const checkLegend = (value) => {
    const dataName = getValue(value);
    if (legends) {
      return Object.keys(legends)?.includes(dataName);
    }
  };

  const handleUrbanizationChange = (urbanization) => (event) => {
    getSelectedValue({
      selectedValue: selectedUrbanization,
      setSelectedValue: setSelectedUrbanization,
      value: urbanization,
    });

    if (!affordability) {
      const currentIndicators = getIndicators(urbanization);

      const currentIndicator = currentIndicators?.find(
        (item) => value === item.value
      );

      setIndicatorData({
        ...indicatorData,
        indicator: currentIndicator?.subValue,
        urbanization: event.target.value,
      });
    } else {
      getAffordabilityData({
        category: "rural_urban",
        value,
        subCategory: urbanization,
      });
    }

    setSelectedGender([]);
    setSelectedAge([]);

    getScrollPosition();
  };

  const handleGenderChange = (gender) => (event) => {
    getSelectedValue({
      selectedValue: selectedGender,
      setSelectedValue: setSelectedGender,
      value: gender,
    });

    if (!affordability) {
      const indicators = getIndicators(gender);

      const currentIndicator = indicators?.find((item) => value === item.value);

      setIndicatorData({
        ...indicatorData,
        indicator: currentIndicator?.subValue,
        gender: event.target.value,
      });
    } else {
      getAffordabilityData({ category: "gender", value, subCategory: gender });
    }

    setSelectedUrbanization([]);
    setSelectedAge([]);

    getScrollPosition();
  };

  const handleAgeChange = (age) => (event) => {
    getSelectedValue({
      selectedValue: selectedAge,
      setSelectedValue: setSelectedAge,
      value: age,
    });

    const indicators = getIndicators(age);

    const currentIndicator = indicators?.find((item) => value === item.value);

    setIndicatorData({
      ...indicatorData,
      indicator: currentIndicator?.subValue,
      age: event.target.value,
    });

    setSelectedUrbanization([]);
    setSelectedGender([]);

    getScrollPosition();
  };

  const isShownSubCategory =
    affordability.includes("cablefixedmin") ||
    affordability.includes("cablefixedavg") ||
    affordability.includes("cablefixedmax") ||
    affordability.includes("itu_datamobile") ||
    affordability.includes("datavoicelow") ||
    affordability.includes("datavoicehigh") ||
    affordability.includes("itu_mobile1gb") ||
    affordability.includes("itu_mobile5gb") ||
    affordability.includes("itu_mobile10gb") ||
    affordability.includes("itufixed") ||
    affordability.includes("income");

  const getOptions = (categories) => {
    const options = categories
      .map((category) => {
        if (checkLegend(category)) {
          return category;
        } else if (isShownSubCategory) {
          return category;
        } else {
          return null;
        }
      })
      .filter((item) => item);

    return options;
  };

  const getFilteredOptions = (type, classes) => {
    return affordability
      ? !ituDataList.includes(
          `${newCountry}_itu_${layerLevel}_${type}_affordability`
        )
        ? []
        : getOptions(classes)
      : getOptions(classes);
  };

  const urbanizationOptions = getFilteredOptions(
    "rural_urban",
    urbanizationClasses
  );
  const genderOptions = getFilteredOptions("gender", genders);

  const agesOption = getOptions(ages);

  const formProps = [
    {
      title: "Select the urbanization level (Rural/Urban)",
      options: urbanizationOptions,
      selectedValue: selectedUrbanization,
      handleChange: handleUrbanizationChange,
    },
    {
      title: "Select the gender class (Male/Female)",
      options: genderOptions,
      selectedValue: selectedGender,
      handleChange: handleGenderChange,
    },
    {
      title: "Select the age class (Adult/Young)",
      options: agesOption,
      selectedValue: selectedAge,
      handleChange: handleAgeChange,
    },
  ];

  const validProps = formProps?.filter((data) => data?.options?.length > 0);

  const propsPerValue = affordability ? validProps.filter(data => !data.title.includes("age")) : validProps;

  return (
    <div className={SUB_SECTION}>
      {propsPerValue.map((props, index) => {
        const { title, options, selectedValue, handleChange } = props;
        const list = ["a", "b", "c"];
        return (
          options.length > 0 && (
            <div key={index}>
              <span
                className={CONTROLLER_TITLE}
              >{`${list[index]}. ${title}`}</span>
              {options?.sort().map((option, index) => {
                const newLabel =
                  option === "adult"
                    ? "adult (>24 yrs)"
                    : option === "young"
                    ? "young (15-24 yrs)"
                    : option;
                return (
                  <FormControlLabel
                    key={index}
                    value={option}
                    className={FORM_CONTROL_LABEL}
                    onChange={handleChange(option)}
                    control={renderController(option, selectedValue)}
                    label={titleCase(newLabel)}
                  />
                );
              })}
            </div>
          )
        );
      })}
    </div>
  );
};

export default IndicatorChildren;
