import { Box, Typography } from "@mui/material";
import "./chart-legends.scss";
import { titleCase, toKebabCase } from "../../utils/strings";

const ChartLegends = ({
  mainLegend,
  subLegend = [],
  type = "",
  multipleData = [],
}) => {
  const title = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <Box className="custom-legends">
      <Box className="custom-legends-content">
        {type !== "multiple"
          ? mainLegend.map((item) => (
              <Box key={item}>
                <Typography className="legend-title">
                  {titleCase(item)}
                </Typography>

                <Box className="legend-container">
                  {subLegend.map((subItem) => {
                    const category = toKebabCase(item);
                    const subCategory = toKebabCase(subItem);
                    return (
                      <div key={subItem} className="legend-detail">
                        <span
                          className={`color ${subCategory}-color-${category}`}
                        />
                        <span className="color-label">{title(subItem)}</span>
                      </div>
                    );
                  })}
                </Box>
              </Box>
            ))
          : mainLegend.map(
              (item, index) =>
                multipleData[index].flat().length > 0 && (
                  <Box key={item}>
                    <Typography className="legend-title">
                      {titleCase(item)}
                    </Typography>

                    <Box className="legend-container">
                      {multipleData[index]?.map((subItem) => {
                        const category = toKebabCase(item);
                        const subCategory = toKebabCase(subItem);
                        return (
                          <div key={subItem} className="legend-detail">
                            <span className={`color ${subCategory}-color-${category}`} />
                            <span className="color-label">
                              {title(subItem)}
                            </span>
                          </div>
                        );
                      })}
                    </Box>
                  </Box>
                )
            )}
      </Box>
    </Box>
  );
};

export default ChartLegends;
