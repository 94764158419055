import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMap } from "react-leaflet";
import * as WMS from "leaflet.wms";
import { Context } from "../../../GlobalContext";
import { REPORT } from "../../../utils/path";

const CustomWMSLayer = ({ url, options, layers }) => {
  const map = useMap();
  const { pathname } = useLocation();
  const addedLayers = [];
  const { cacheCleaner } = useContext(Context);
  const [popupContent, setPopupContent] = useState(null);
  const popupElement = document.querySelector(".leaflet-popup-pane");
  const caption = document?.querySelector("caption");
  const tableCell = document.querySelectorAll("tr > th")[1];
  const tableBody = document.querySelector("tbody");
  const popupLink = popupElement?.querySelector("a");

  /* Clear all layer stored in the map instance.
  Since some layers don't have the method onRemove(), they have to be cleaned by mutating the layers stored in map instance when navigating to a new route */
  useEffect(() => {
    if (cacheCleaner || pathname === REPORT) {
      map._layers = {};
    }
  }, [cacheCleaner, map, pathname]);

  // Redirect link to a different tab
  if (popupLink) {
    popupLink.setAttribute("target", "__blank");
  }

  // Modify wind speed popup caption and table cell label
  if (caption && caption.innerHTML === "global_windspeed_80m") {
    caption.innerHTML = "Wind Speed (m/s)";
    tableCell.innerHTML = "Gray Index";
    tableBody.classList.add("wind-speed-table");
  }

  useEffect(() => {
    const source = WMS.source(url, options);

    layers.forEach((name) => {
      const layer = source.getLayer(name);

      if (layer) {
        map?._popup?._content && setPopupContent(map?._popup?._content);
        map?.addLayer(layer);
        addedLayers.push(layer);
      }
    });

    // Update the popup content when it changes in the map.
    const updatePopupContent = () =>
      map?._popup?._content && setPopupContent(map?._popup?._content);

    map?.on("popupopen", updatePopupContent);

    return () => {
      addedLayers.forEach((layer) => map?.removeLayer(layer));
      map?.off("popupopen", updatePopupContent);
    };
    // eslint-disable-next-line
  }, [layers.length]);

  // Update the popup style based on the condition
  const shouldActivatePopup = () => {
    return (
      (popupContent && popupContent.includes("tooltip") && layers?.length > 0) ||
      (popupContent && popupContent.includes("<iframe")) ||
      (popupContent && popupContent.includes("GRAY_INDEX"))
    );
  };

  useEffect(() => {
    if (!popupElement) return;

    if (shouldActivatePopup()) {
      popupElement.classList.add("active");
    } else {
      map?.closePopup();
      popupElement.classList.remove("active");
    }
    // eslint-disable-next-line
  }, [popupContent, popupElement, layers.length]);

  return null;
};

export default CustomWMSLayer;
