import { useContext, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import { CloseOutlined } from "@mui/icons-material";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import "./search-country.scss";
import { Context } from "../../../GlobalContext";
import { REPORT } from "../../../utils/path";
import { reportZoomLevel } from "../../../utils/numbers";
import { DRC, countriesString, titleCase } from "../../../utils/strings";
import { checkExistingData } from "../../../utils/api";
import { useLocation } from "react-router-dom";

const { KAZAKHSTAN, MONGOLIA } = countriesString;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": { backgroundColor: alpha(theme.palette.common.white, 0.25) },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const getInitialZoomLevel = (countryName, pathname) => {
  if (pathname === REPORT) {
    if (countryName === KAZAKHSTAN.toLowerCase() || countryName === MONGOLIA.toLowerCase()) {
      return 4;
    } else if (countryName === DRC) {
      return 5;
    } else {
      return reportZoomLevel;
    }
  } else {
    return countryName === KAZAKHSTAN.toLowerCase() || countryName === MONGOLIA.toLowerCase() ? 5 : 6;
  }
};

const SearchCountry = () => {
  const {
    setMessage,
    map,
    setItuDataList,
    setPosition,
    countries,
    countryName,
    setCountryName,
    setIndicatorData,
    setSelectedDatasets,
    setIsOpenedDrawer,
    setAffordabilitySelection,
  } = useContext(Context);
  const [inputValue, setInputValue] = useState(titleCase(countryName));
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();

  const getSearchedCountry = async (countryName) => {
    setIsLoading(true);

    // Reset all activated selections or filters
    setSelectedDatasets([]);

    setIsOpenedDrawer(false);

    setIndicatorData({ layerLevel: "", year: "", indicator: "" });

    setAffordabilitySelection([]);

    const zoomLevel = getInitialZoomLevel(countryName, pathname);

    const MAP_URL = `https://nominatim.openstreetmap.org/search?country=${countryName}&format=json`;

    try {
      const response = await fetch(MAP_URL);
      const data = await response.json();

      if (map && data?.length > 0) {
        const { lat, lon } = data[0];

        map?.flyTo([lat, lon], zoomLevel);

        setPosition({ lat: Number(lat), lon: Number(lon) });
      } else {
        setMessage(
          "The country that you are looking for could not be found. Could you try another country please?"
        );
      }

      checkExistingData(countryName, setMessage, setItuDataList);
    } catch (error) {
      setMessage(
        "Country not found or there is a problem with your connection, please try again."
      );
    }
    setCountryName(countryName);
    setIsLoading(false);
  };

  return (
    <Search>
      <Autocomplete
        freeSolo={true}
        options={countries?.sort() || []}
        value={inputValue}
        inputValue={inputValue}
        filterSelectedOptions={true}
        onChange={(_event, newValue) => {
          const value = newValue?.toLowerCase();
          if (newValue) {
            getSearchedCountry(value);
            setCountryName(value);
          }
        }}
        onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
        className={`auto-completed-input ${isLoading && "loading-input"}`}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            className="search-input"
            placeholder="Search a country"
          />
        )}
        clearIcon={
          isLoading ? (
            <CircularProgress size={20} className="loading-icon" />
          ) : countryName?.length > 0 ? (
            <CloseOutlined color="#fff" size="small" />
          ) : (
            ""
          )
        }
      />
    </Search>
  );
};

export default SearchCountry;
