import { POPULATION } from "./strings";

export const roundValue = (value, name = "") => {
  if (value === 0) {
    return 0;
  } else if (value === 100) {
    return value;
  } else if (name === POPULATION) {
    return Math.round(value);
  } else {
    let fixedString = value?.toFixed(2);
    let decimalPlaces = 2;

    for (let i = 3; i <= 20; i++) {
      if (i === 20 || (fixedString.charAt(fixedString.length - 1) === "0" && fixedString.charAt(fixedString.length - 2) === "0")) {
        decimalPlaces++;
        fixedString = value?.toFixed(decimalPlaces);
        if (i === 20 && fixedString.charAt(fixedString.length - 1) === "0") {
          return Math.round(value);
        }
      } else {
        break;
      }
    }

    return Number(fixedString);
  }
};

export const reportZoomLevel = 5.4;

export const getDataSum = (data) =>
  data?.length > 0 && data.reduce((acc, val) => acc + val, 0);
