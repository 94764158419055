import { useContext } from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import { getRoadColor, roadLegends } from '../../../../../utils/legends';
import { titleCase } from "../../../../../utils/strings";
import { Context } from "../../../../../GlobalContext";

const Roads = () => {
  const { countryName } = useContext(Context);
  const legendPerCountry = roadLegends.find(legend => legend.country.toLowerCase() === countryName || legend.country.toLowerCase() === "kazakhstan");

  const renderRoadListItem = (road, index) => (
    <ListItem key={index} className="list-item">
      <span
        className={`road-status-color long-bar ${road}`}
        style={{
          opacity: 1,
          backgroundColor: getRoadColor(road),
          height: road.includes("link") ? 4 : 6,
        }}
      />
      {titleCase(road.split("_").join(" "))}
    </ListItem>
  );

  return (
    <Box className="road legend-item">
      <Typography component="h4">Road network</Typography>
      <List className="list">
        {legendPerCountry?.legend?.sort()?.map(renderRoadListItem)}
      </List>
    </Box>
  );
};

export default Roads;
