import { Box } from "@mui/system";
import { List, ListItem, Typography } from "@mui/material";
import { windWpeedLegend } from "../../../../../utils/legends";

const WindSpeed = () => {
  return (
    <Box className="natural-hazard legend-item wind-speed">
      <Typography component="h4" className="legend-title">
        Wind Speed (m/s)
      </Typography>
      <List className="list">
        <ListItem
          className="list-item"
          style={{
            background: `linear-gradient(${windWpeedLegend
              .map(({ color }) => color)
              .join(" ,")})`,
          }}
        >
          {windWpeedLegend.map(({ label }, index) => (
            <div key={index} className="list-item">
              <span className="status-color square" />
              <span className="label ">{label}</span>
            </div>
          ))}
        </ListItem>
      </List>
    </Box>
  );
};

export default WindSpeed;
