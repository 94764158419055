import { useContext, useEffect, useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import "./country-level.scss";

import { Context } from "../../../GlobalContext";

import {
  activeMobileUser,
  broadbandPlans,
  broadbandSubscriptions,
  internetSpeed,
  urbanizationClasses,
  years,
} from "../../../utils/misc";
import { getDataSum } from "../../../utils/numbers";

import {
  getAffordabilitySeries,
  getSeriesByGender,
  getSeriesByUrbanization,
} from "../../../utils/country-charts";

import CardItem from "./CardItem";
import { DRC, baseUrl } from "../../../utils/strings";
import { fetchData } from "../../../utils/api";

const CountryLevel = () => {
  const { isLoadPdf, setMessage, countryName } = useContext(Context);
  const [dataIndex, setDataIndex] = useState(null);
  const [avgAffordFixedMobile, setAvgAffordFixedMobile] = useState(null);
  const [avgFixedMobile, setAvgFixedMobile] = useState(null);
  const [avgInternetSpeed, setAvgInternetSpeed] = useState(null);
  const [avgMobileUsers, setAvgMobileUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const newCountryName = countryName === DRC ? "drc" : countryName;
  const mobileAndUsers = ["mobile", "users"];
  const mobileFixed = ["fixed", "mobile"];
  const internetSpeeds = ["fixed_256_2", "fixed_2_10", "fixed_above10"];

  const fetchAvgData = async (endpoint, setStateFunction) => {
    const data = await fetchData(`${baseUrl}:8888${endpoint}?country_name=${newCountryName}`, setMessage);
    if(data && !data?.error) {
      setStateFunction(data);
    } else {
      setStateFunction(null);
    }
  };

  const getBroadband = (data, category, mainProp, year) => {
    const property = `province_${mainProp}${year}_${category}_${year}`;
    if (data) {
      return Number(data[property]) ? Number(data[property]) : 0;
    }
  };

  const getAffordValue = (keyword, year) => {
    const affordProp = `province_non_aggregated_afford_${keyword}_${year}`;

    if (avgAffordFixedMobile) {
      const value = avgAffordFixedMobile[affordProp] === "unaffordable" ? 100 : Number(avgAffordFixedMobile[affordProp]) || 0;
      return value;
    }
  };

  const fixedMinMobile1Gb2020 = [
    getAffordValue("cablefixedmin", 2020),
    getAffordValue("itu_mobile1gb", 2020),
  ];

  const fixedAvgMobile5Gb2020 = [
    getAffordValue("cablefixedavg", 2020),
    getAffordValue("itu_mobile5gb", 2020),
  ];

  const fixedMaxMobile10Gb2020 = [
    getAffordValue("cablefixedmax", 2020),
    getAffordValue("itu_mobile10gb", 2020),
  ];

  const fixedMinMobile1Gb2030 = [
    getAffordValue("cablefixedmin", 2030),
    getAffordValue("itu_mobile1gb", 2030),
  ];

  const fixedAvgMobile5Gb2030 = [
    getAffordValue("cablefixedavg", 2030),
    getAffordValue("itu_mobile5gb", 2030),
  ];

  const fixedMaxMobile10Gb2030 = [
    getAffordValue("cablefixedmax", 2030),
    getAffordValue("itu_mobile10gb", 2030),
  ];

  const affordabilityData = [
    fixedMinMobile1Gb2020,
    fixedAvgMobile5Gb2020,
    fixedMaxMobile10Gb2020,
    fixedMinMobile1Gb2030,
    fixedAvgMobile5Gb2030,
    fixedMaxMobile10Gb2030,
  ].flat();

  const hasAffordability = getDataSum(affordabilityData) > 0;

  const getMappedValues = (currentData, data, category, year) =>
    data.map((item) => {
      const mainData = getBroadband(currentData, item, category, year);
      return mainData || 0;
    });

  const mobileUserFemale2020 = getMappedValues(avgMobileUsers, mobileAndUsers, "female", 2020);
  const mobileUserMale2020 = getMappedValues(avgMobileUsers, mobileAndUsers, "male", 2020);
  const mobileUserFemale2030 = getMappedValues(avgMobileUsers, mobileAndUsers, "female", 2030);
  const mobileUserMale2030 = getMappedValues(avgMobileUsers, mobileAndUsers, "male", 2030);

  const fixedMobileRural2020 = getMappedValues(avgFixedMobile, mobileFixed, "rural", 2020);
  const fixedMobileUrban2020 = getMappedValues(avgFixedMobile, mobileFixed, "urban", 2020);
  const fixedMobileRural2030 = getMappedValues(avgFixedMobile, mobileFixed, "rural", 2030);
  const fixedMobileUrban2030 = getMappedValues(avgFixedMobile, mobileFixed, "urban", 2030);

  const fixedRural2020 = getMappedValues(avgInternetSpeed, internetSpeeds, "rural", 2020);
  const fixedUrban2020 = getMappedValues(avgInternetSpeed, internetSpeeds, "urban", 2020);
  const fixedRural2030 = getMappedValues(avgInternetSpeed, internetSpeeds, "rural", 2030);
  const fixedUrban2030 = getMappedValues(avgInternetSpeed, internetSpeeds, "urban", 2030);

  const hasSubscriberPerGender = [
    getDataSum(mobileUserFemale2020),
    getDataSum(mobileUserMale2020),
    getDataSum(mobileUserFemale2020),
    getDataSum(mobileUserMale2020),
  ];

  const hasBroadbandSubscriptions = [
    getDataSum(fixedMobileRural2020),
    getDataSum(fixedMobileUrban2020),
    getDataSum(fixedMobileRural2030),
    getDataSum(fixedMobileUrban2030),
  ];

  const hasFixedBroadband = [
    getDataSum(fixedRural2020),
    getDataSum(fixedUrban2020),
    getDataSum(fixedRural2030),
    getDataSum(fixedUrban2030),
  ];

  const isShownCountryCard =
    avgMobileUsers || avgFixedMobile || avgMobileUsers || hasAffordability;

  const getAffordabilityLegendValue = () => {
    const processData = (minData, avgData, maxData) => {
      const data = [];
      if (getDataSum(minData) > 0) {
        data.push("Minimum / 1Gb");
      }

      if (getDataSum(avgData) > 0) {
        data.push("Average / 5Gb");
      }

      if (getDataSum(maxData) > 0) {
        data.push("Maximum / 10Gb");
      }
      return data;
    };

    const firstData = processData(fixedMinMobile1Gb2020, fixedAvgMobile5Gb2020, fixedMaxMobile10Gb2020);
    const secondData = processData(fixedMinMobile1Gb2030, fixedAvgMobile5Gb2030, fixedMaxMobile10Gb2030);

    return [firstData, secondData];
  };

  const getLegendValue = (checkers, legendLabels) => {
    const processFirstData = () => {
      let data = [];
      if (checkers[0] > 0) {
        data = [...data, legendLabels[0]];
      }
      if (checkers[1] > 0) {
        data = [...data, legendLabels[1]];
      }
      return data;
    };

    const processSecondData = () => {
      let data = [];
      if (checkers[2] > 0) {
        data = [...data, legendLabels[0]];
      }
      if (checkers[3] > 0) {
        data = [...data, legendLabels[1]];
      }
      return data;
    };

    const firstData = processFirstData();
    const secondData = processSecondData();

    return [firstData, secondData];
  };

  useEffect(() => {
    setLoading(true);
    fetchAvgData(`/api/average_fixed_and_mobile`, setAvgFixedMobile);
    fetchAvgData(`/api/average_affordability_mobile_and_fixed`, setAvgAffordFixedMobile);
    fetchAvgData(`/api/average_speed`, setAvgInternetSpeed);
    fetchAvgData(`/api/average_mobile_and_users`, setAvgMobileUsers);
    setLoading(false);
    // eslint-disable-next-line
  }, [newCountryName]);

  return isShownCountryCard ? (
    <Card className="card main-card country-level-card" sx={{ boxShadow: 3 }}>
      <Typography component="h2">Country Level</Typography>

      <Box className="cards-wrapper">
        <CardItem
          title="Broadband subscribers in rural and urban areas"
          data={broadbandSubscriptions}
          hasData={avgFixedMobile}
          series={
            avgFixedMobile
              ? getSeriesByUrbanization(
                  fixedMobileRural2020,
                  fixedMobileUrban2020,
                  fixedMobileRural2030,
                  fixedMobileUrban2030
                )
              : []
          }
          loading={loading}
          isLoadPdf={isLoadPdf}
          mainLegend={years}
          multipleData={getLegendValue(hasBroadbandSubscriptions, urbanizationClasses)}
          legendType="multiple"
        />

        <CardItem
          title="Fixed broadband subscribers by speed in rural and urban areas"
          data={internetSpeed}
          hasData={avgInternetSpeed}
          series={
            avgInternetSpeed
              ? getSeriesByUrbanization(
                  fixedRural2020,
                  fixedUrban2020,
                  fixedRural2030,
                  fixedUrban2030
                )
              : []
          }
          loading={loading}
          isLoadPdf={isLoadPdf}
          mainLegend={years}
          multipleData={getLegendValue(hasFixedBroadband, urbanizationClasses)}
          legendType="multiple"
        />

        <CardItem
          title="Mobile broadband subscribers and Internet users by gender"
          data={activeMobileUser}
          hasData={avgMobileUsers}
          series={
            avgMobileUsers
              ? getSeriesByGender(
                  mobileUserFemale2020,
                  mobileUserMale2020,
                  mobileUserFemale2030,
                  mobileUserMale2030
                )
              : []
          }
          loading={loading}
          isLoadPdf={isLoadPdf}
          mainLegend={years}
          multipleData={getLegendValue(hasSubscriberPerGender, years)}
          legendType="multiple"
        />

        <CardItem
          title="Affordability of fixed and mobile broadband plans"
          data={broadbandPlans}
          hasData={hasAffordability}
          series={
            hasAffordability
              ? getAffordabilitySeries(
                  fixedMinMobile1Gb2020,
                  fixedAvgMobile5Gb2020,
                  fixedMaxMobile10Gb2020,
                  fixedMinMobile1Gb2030,
                  fixedAvgMobile5Gb2030,
                  fixedMaxMobile10Gb2030,
                  dataIndex
                )
              : []
          }
          legendType="multiple"
          loading={loading}
          isLoadPdf={isLoadPdf}
          mainLegend={years}
          multipleData={getAffordabilityLegendValue()}
          onHighlightChange={(bar) => setDataIndex(bar?.dataIndex)}
        />
      </Box>
    </Card>
  ) : (
    ""
  );
};

export default CountryLevel;
