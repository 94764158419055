import { useContext, useEffect, useState } from "react";
import { Box, Card, CardHeader, Divider, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";

import "./subnational-level.scss";

import { Context } from "../../../GlobalContext";
import { DRC, baseUrl, titleCase } from "../../../utils/strings";
import { getDataSum } from "../../../utils/numbers";
import {
  broadbandPlans,
  broadbandSubscriptions,
  genders,
  urbanizationClasses,
  usersMobile,
  years,
} from "../../../utils/misc";

import { getAffordabilityByCategory, getFixedBroadband, getFixedMobilePerUrbanization, getUsersMobilePerGender } from "../../../utils/subnational-charts";
import { getChartProps, highlightBar } from "../../../utils/charts";

import ChartLegends from "../../../components/ChartLegends/ChartLegends";
import Form from "./Form";
import { fetchData } from "../../../utils/api";

const getDataValue = (value) => (value ? Number(value) : 0);

const renderAffordLegends = (data) => {
  const getAffordabilityLegends = (data, year) => {
    const filteredData = data?.filter(item => item?.data?.some(val => val > 0) && item?.label?.includes(year.toString())).map(item => item?.value);
    return filteredData;
  };

  const fixedPlans2020 = getAffordabilityLegends(data, 2020)?.filter(item => item === "Minimum" || item === "Average" || item === "Maximum");
  const mobilePlans2020 = getAffordabilityLegends(data, 2020)?.filter(item => item === "1Gb" || item === "5Gb" || item === "10Gb");
  const fixedPlans2030 = getAffordabilityLegends(data, 2030)?.filter(item => item === "Minimum" || item === "Average" || item === "Maximum");
  const mobilePlans2030 = getAffordabilityLegends(data, 2030)?.filter(item => item === "1Gb" || item === "5Gb" || item === "10Gb");

  const getMainLegends = (firstData, secondData) => {
    let data = [];
    if (firstData.length > 0) {
      data.push(broadbandPlans[0]);
    }

    if (secondData.length > 0) {
      data.push(broadbandPlans[1]);
    }
    return data;
  }

  return (
    <div className="affordability-legends">
      {(fixedPlans2020.length > 0 || mobilePlans2020.length > 0) && (
        <div className="affordability-2020">
          <Typography component="p">2020</Typography>
          <ChartLegends
            mainLegend={getMainLegends(fixedPlans2020, mobilePlans2020)}
            multipleData={[fixedPlans2020, mobilePlans2020]}
            type="multiple"
          />
        </div>
      )}

      {(fixedPlans2030.length > 0 || mobilePlans2030.length > 0) && (
        <div className="affordability-2030">
          <Typography component="p">2030</Typography>
          <ChartLegends
            mainLegend={getMainLegends(fixedPlans2030, mobilePlans2030)}
            multipleData={[fixedPlans2030, mobilePlans2030]}
            type="multiple"
          />
        </div>
      )}
    </div>
  );
};

const SubNationalLevel = () => {
  const affordabilityProps = {
    gender: [],
    rural_urban: [],
    non_aggregated: [],
  };
  const { countryName, isLoadPdf, setMessage } = useContext(Context);
  const [layerLevel, setLayerLevel] = useState("province");
  const [loading, setLoading] = useState(false);
  const [adminList, setAdminList] = useState([]);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [provinceAffordability, setProvinceAffordability] = useState(affordabilityProps);
  const [districtAffordability, setDistrictAffordability] = useState(affordabilityProps);
  const adminName = layerLevel === "district" ? "adm2_pt" : "adm1_pt";
  const newCountryName = countryName === DRC ? "drc" : countryName;

  const dataPerAdmin = layerLevel === "district" ? districts : provinces;

  const affordPerAdmin = layerLevel === "district" ? districtAffordability : provinceAffordability;

  const { gender, rural_urban, non_aggregated } = affordPerAdmin;

  const hasData = dataPerAdmin?.length > 0;

  const getFilteredPerAdmin = (data) => {
    if (data?.length > 0 && hasData) {
      const dataPerAdmin = data?.map((item) => {
            const filteredAdmin = selectedAdmins?.map((selectedItem) => {
              if (selectedItem === item[adminName]) {
                return item;
              }
              return undefined;
            });

            return filteredAdmin;
          })
          .flat() || [];

      const dataPerSelectedAdmin = dataPerAdmin?.filter((data) => data !== undefined);

      return dataPerSelectedAdmin;
    }
  };

  // Charts series data
  const filteredDataPerAdmin = getFilteredPerAdmin(dataPerAdmin);

  const filteredGenderPerAdmin = getFilteredPerAdmin(gender);

  const filteredUrbanizationPerAdmin = getFilteredPerAdmin(rural_urban);

  const filteredNonAggregatedPerAdmin = getFilteredPerAdmin(non_aggregated);

  // Charts common properties or settings
  const chartMainProps = (hasData) => ({ data: selectedAdmins.sort(), hasData, height: 300, loading, isLoadPdf });

  // Charts values checkers
  const getValue = (mainProp, category, year) => {
    const indicatorName = `${layerLevel}_${mainProp}_${year}`;
    const subIndicatorName = `${layerLevel}_${category}${year}.${mainProp}_${year}`;
    const propertyName = category.length > 0 ? subIndicatorName : indicatorName;

    if (hasData) {
      return filteredDataPerAdmin.map((data) => {
        return getDataValue(data[propertyName]);
      });
    }
  };

  const getAffordability = (data, mainProp, category, year) => {
    if (data?.length > 0 && category) {
      const affordabilityData = data?.map((item) => {
        const propertyName = `${layerLevel}_${category}_afford.${mainProp}_${year}`;
        const value = item[propertyName] || 0;

        return value === "unaffordable" ? 100 : getDataValue(value);
      });

      return affordabilityData;
    }
  };

  const hasValue = (mainProp, category, year) => {
    if (category.length > 0) {
      return getDataSum(getValue(mainProp, category, year));
    } else {
      return getDataSum(getValue(mainProp, "", year));
    }
  };

  // Options for showing or hiding charts
  const hasMobileUsersSubscribersPerGenders =
    hasValue("mobile", "female", 2020) ||
    hasValue("mobile", "male", 2020) ||
    hasValue("users", "female", 2020) ||
    hasValue("users", "male", 2020) ||
    hasValue("mobile", "female", 2030) ||
    hasValue("mobile", "male", 2030) ||
    hasValue("users", "female", 2030) ||
    hasValue("users", "male", 2020);

  const hasFixedMobileSubscribersUrbanization =
    hasValue("mobile", "rural", 2020) ||
    hasValue("mobile", "urban", 2020) ||
    hasValue("fixed", "rural", 2020) ||
    hasValue("fixed", "urban", 2020) ||
    hasValue("mobile", "rural", 2030) ||
    hasValue("mobile", "urban", 2030) ||
    hasValue("fixed", "rural", 2030) ||
    hasValue("fixed", "urban", 2020);

  const hasFixedBroadband =
    hasValue("fixed_256_2", "", 2020) ||
    hasValue("fixed_2_10", "", 2020) ||
    hasValue("fixed_above10", "", 2020) ||
    hasValue("fixed_256_2", "", 2030) ||
    hasValue("fixe d_2_10", "", 2030) ||
    hasValue("fixed_above10", "", 2030);

  const showAffordability = (data = [0, 0], category) => {
    if (data?.length > 0 && category) {
      const isShownAffordability =
        getAffordability(data, "cablefixedmin", category, 2020) ||
        getAffordability(data, "cablefixedavg", category, 2020) ||
        getAffordability(data, "cablefixedmax", category, 2020) ||
        getAffordability(data, "itu_mobile1gb", category, 2020) ||
        getAffordability(data, "itu_mobile5gb", category, 2020) ||
        getAffordability(data, "itu_mobile10gb", category, 2020) ||
        getAffordability(data, "cablefixedmin", category, 2030) ||
        getAffordability(data, "cablefixedavg", category, 2030) ||
        getAffordability(data, "cablefixedmax", category, 2030) ||
        getAffordability(data, "itu_mobile1gb", category, 2030) ||
        getAffordability(data, "itu_mobile5gb", category, 2030) ||
        getAffordability(data, "itu_mobile10gb", category, 2030);

      return getDataSum(isShownAffordability) > 0;
    }
  };

  const hasAffordPerMale = showAffordability(filteredGenderPerAdmin, "male");

  const hasAffordPerFemale = showAffordability(filteredGenderPerAdmin, "female");

  const hasAffordabilityPerRural = showAffordability(filteredUrbanizationPerAdmin, "rural");

  const hasAffordabilityPerUrban = showAffordability(filteredUrbanizationPerAdmin, "urban");

  const hasNonAggregatedAffordability = showAffordability(filteredNonAggregatedPerAdmin, "non.aggregated");

  const isNoData =
  !hasFixedBroadband &&
  !hasFixedMobileSubscribersUrbanization &&
  !hasMobileUsersSubscribersPerGenders &&
  !hasNonAggregatedAffordability &&
  !hasAffordabilityPerRural &&
  !hasAffordabilityPerUrban &&
  !hasAffordPerMale &&
  !hasAffordPerFemale;

  const noDataClassName = selectedAdmins.length === 0 || isNoData ? "no-data" : "";

  const getAdminLevels = async() => {
    const data = await fetchData(`${baseUrl}:8888/api/admin_levels?country_name=${newCountryName}&layer_level=${layerLevel}`, setMessage);
    if (data && !data?.error) {
      setAdminList([...new Set(data)].sort());
    } else {
      setAdminList([]);
    }
  }

  const getItuReport = async(layerLevel, setAdminLevel) => {
    const admins = selectedAdmins?.map(admin => `selected_admins=${admin}`);
    const data = await fetchData(`${baseUrl}:8888/api/itu_by_layer_level?layer_level=${layerLevel}&country_name=${newCountryName}&${admins.join("&")}`, setMessage);
    if (data && !data?.error) {
      setAdminLevel(data);
    } else {
      setAdminLevel([]);
    }
  }

  const getAffordabilityReport = async(layerLevel, category, setAdminLevel) => {
    const admins = selectedAdmins?.map(admin => `selected_admins=${admin}`);
    const data = await fetchData(`${baseUrl}:8888/api/affordability_by_layer_level?layer_level=${layerLevel}&country_name=${newCountryName}&${admins.join("&")}&category=${category}`, setMessage);
    if (data && !data?.error) {
      setAdminLevel((prev) => ({ ...prev, [category]: data }));
    } else {
      setAdminLevel(affordabilityProps);
    }
  }

  useEffect(() => {
    getAdminLevels();

    if (adminList.length > 0) {
      const defaultSelectedAdmin = adminList.slice(0, 3);
      setSelectedAdmins(defaultSelectedAdmin);
    }

    // eslint-disable-next-line
  }, [newCountryName, layerLevel, adminList.length]);

  useEffect(() => {
    const affordabilityCategories = ["rural_urban", "gender", "non_aggregated"];

    if (selectedAdmins.length > 0) {
      setLoading(true);

      if (layerLevel === "province") {
        getItuReport("province", setProvinces);

        affordabilityCategories.forEach((category) => {
          getAffordabilityReport("province", category, setProvinceAffordability);
        });
      } else {
        getItuReport("district", setDistricts);

        affordabilityCategories.forEach((category) => {
          getAffordabilityReport("district", category, setDistrictAffordability);
        });
      }

      setLoading(false);
    }

    // eslint-disable-next-line
  }, [newCountryName, layerLevel, selectedAdmins]);


  const getFixedBroadbandLegendValue = () => {
    const processData = (year) => {
      const data = [];
      if (hasValue("fixed_256_2", "", year)) {
        data.push("256 kbit/s to 2 Mbit/s");
      }

      if (hasValue("fixed_2_10", "", year)) {
        data.push("2 Mbit/s to 10 Mbit/s");
      }

      if (hasValue("fixed_above10", "", year)) {
        data.push("Above 10 Mbit/s");
      }
      return data;
    };

    const firstData = processData(2020);
    const secondData = processData(2030);

    return [firstData, secondData];
  };

  const getLegendValue = (checkers, legendLabels) => {
    const processData = () => {
      const data = [];
      if (checkers[0]) {
        data.push(legendLabels[0]);
      }

      if (checkers[1]) {
        data.push(legendLabels[1]);
      }

      if (checkers[2]) {
        data.push(legendLabels[2]);
      }

      if (checkers[3]) {
        data.push(legendLabels[3]);
      }

      return [...new Set(data)];
    };

    const data = processData().filter(item => Boolean(item));

    return [data, data];
  };

  return (
    adminList?.length > 0 && (
      <Card className={`card main-card subnational-level ${noDataClassName}`} sx={{ boxShadow: 3 }}>
        <Typography component="h2">
          {titleCase(layerLevel)} Level
        </Typography>

        <Form
          {...{
            layerLevel,
            setLayerLevel,
            adminList,
            adminName,
            selectedAdmins,
            setSelectedAdmins,
          }}
        />

        <Box className="cards-wrapper">
          {selectedAdmins.length === 0 ? (
            <Typography component="h3">
              No {layerLevel} is selected. Please select one or more{" "}
              {layerLevel}s
            </Typography>
          ) : (
            <>
              <CustomCard
                title="Fixed broadband subcriptions by speed"
                className="fixed-broadband-subscriptions"
                hasData={hasFixedBroadband > 0}
                children={
                  <>
                    <ChartLegends
                      mainLegend={years}
                      multipleData={getFixedBroadbandLegendValue()}
                      type="multiple"
                    />

                    <BarChart
                      series={
                        hasFixedBroadband
                          ? highlightBar(getFixedBroadband(getValue))
                          : []
                      }
                      {...getChartProps(chartMainProps(hasFixedBroadband))}
                    />
                  </>
                }
              />

              <CustomCard
                title="Fixed and mobile broadband subscriptions in rural and urban areas"
                className="fixed-and-mobile-subscribers-urbanization"
                hasData={hasFixedMobileSubscribersUrbanization > 0}
                children={
                  <>
                    <ChartLegends
                      mainLegend={broadbandSubscriptions}
                      multipleData={
                        getLegendValue([
                          (hasValue("mobile", "rural", 2020) || hasValue("mobile", "rural", 2030)),
                          (hasValue("mobile", "urban", 2020) || hasValue("mobile", "urban", 2030)),
                          (hasValue("fixed", "rural", 2020) || hasValue("fixed", "rural", 2030)),
                          (hasValue("fixed", "urban", 2020) || hasValue("fixed", "urban", 2030))
                        ],
                        urbanizationClasses
                      )}
                      type="multiple"
                    />

                    <BarChart
                      series={
                        hasFixedMobileSubscribersUrbanization
                          ? highlightBar(getFixedMobilePerUrbanization(getValue))
                          : []
                      }
                      {...getChartProps(chartMainProps(hasFixedMobileSubscribersUrbanization))}
                    />
                  </>
                }
              />

              <CustomCard
                title="Mobile broadband subscriptions and internet users by gender"
                className="mobile-users-subscribers-per-genders"
                hasData={hasMobileUsersSubscribersPerGenders > 0}
                children={
                  <>
                    <ChartLegends
                      mainLegend={usersMobile}
                      multipleData={
                        getLegendValue([
                          (hasValue("mobile", "female", 2020) || hasValue("mobile", "female", 2030)),
                          (hasValue("mobile", "male", 2020) || hasValue("mobile", "male", 2030)),
                          (hasValue("users", "female", 2020) || hasValue("users", "female", 2030)),
                          (hasValue("users", "male", 2020) || hasValue("users", "male", 2030))
                        ],
                        genders
                      )}
                      type="multiple"
                    />

                    <BarChart
                      series={
                        hasMobileUsersSubscribersPerGenders
                          ? highlightBar(getUsersMobilePerGender(getValue))
                          : []
                      }
                      {...getChartProps(chartMainProps(hasMobileUsersSubscribersPerGenders))}
                    />
                  </>
                }
              />

              <CustomCard
                title="Affordability of fixed and mobile plan"
                className="affordability-fixed-mobile-subscribers"
                hasData={hasNonAggregatedAffordability > 0}
                children={
                  <>
                    {renderAffordLegends(getAffordabilityByCategory(filteredNonAggregatedPerAdmin, "non.aggregated", getAffordability))}

                    <BarChart
                      series={
                        hasNonAggregatedAffordability
                          ? highlightBar(
                              getAffordabilityByCategory(filteredNonAggregatedPerAdmin, "non.aggregated", getAffordability)
                            )
                          : []
                      }
                      {...getChartProps(chartMainProps(hasNonAggregatedAffordability))}
                    />
                  </>
                }
              />

              <CustomCard
                title="Affordability of fixed and mobile plan in urban and rural areas"
                className="affordability-fixed-mobile-subscribers-urbanization"
                hasData={hasAffordabilityPerRural || hasAffordabilityPerUrban}
                children={
                  <Affordability
                    categories={urbanizationClasses}
                    data={filteredUrbanizationPerAdmin}
                    hasData={hasAffordabilityPerRural || hasAffordabilityPerUrban}
                    getAffordability={getAffordability}
                    chartProps={{...getChartProps(chartMainProps(hasAffordabilityPerRural || hasAffordabilityPerUrban))}}
                  />
                }
              />

              <CustomCard
                title="Affordability of fixed and mobile broadband plans by gender"
                className="affordability-fixed-mobile-subscribers-gender"
                hasData={hasAffordPerMale || hasAffordPerFemale}
                children={
                  <Affordability
                    categories={genders}
                    data={filteredGenderPerAdmin}
                    hasData={hasAffordPerFemale || hasAffordPerMale}
                    getAffordability={getAffordability}
                    chartProps={{...getChartProps(chartMainProps(hasAffordPerFemale || hasAffordPerMale))}}
                  />
                }
              />
            </>
          )}
        </Box>
      </Card>
    )
  );
};

const CustomCard = ({ title, className, hasData, children }) => {
  return (
    <Card className={`content-card ${className} ${hasData ? "valid-chart" : "empty-chart"}`}>
      <CardHeader title={title} className="card-header" />
      <Divider/>
      {children}
    </Card>
  );
};

const Affordability = ({
  data,
  categories,
  chartProps,
  getAffordability,
  hasData,
}) => {
  return (
    <Box className={`charts-wrapper ${hasData ? "valid-chart" : "empty-chart"}`}>
      {categories.map((category) => (
        <Box key={category}>
          <Typography component="h3">{titleCase(category)}</Typography>

          {renderAffordLegends(getAffordabilityByCategory(data, category, getAffordability))}

          <BarChart
            series={
              hasData
                ? highlightBar(getAffordabilityByCategory(data, category, getAffordability))
                : []
            }
            {...chartProps}
          />
        </Box>
      ))}
    </Box>
  );
};

export default SubNationalLevel;
