import { IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import "./panel-header.scss";

const PanelHeader = ({ setIsOpenedPanel, title }) => {
  return (
    <header className="panel-header">
      <Typography className="panel-title" component="h2">{title}</Typography>
      <IconButton
        size="small"
        aria-label="close"
        className="close-button"
        onClick={() => setIsOpenedPanel(false)}
      >
        <CloseOutlined fontSize="inherit" />
      </IconButton>
    </header>
  );
};

export default PanelHeader;
