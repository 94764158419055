import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import L from "leaflet";
import { GeoJSON, Pane } from "react-leaflet";
import { Typography } from "@mui/material";
import { renderToString } from "react-dom/server";
import { Context } from "../../../GlobalContext";
import { ACTIVE_MOBILE,CELL_TOWER_COUNT, FIXED_SPEED, INTERNET_USERS, getLayer } from "../../../utils/strings";
import { drawerData } from "../../../utils/misc";
import { controlLayer, darkModeColor } from "../../../utils/tiles";
import { REPORT } from "../../../utils/path";

const Drawer = () => {
  const {
    map,
    darkMode,
    countryName,
    sliderData,
    existingData,
    setCellTowerRange,
  } = useContext(Context);
  const { pathname } = useLocation();
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ lat: 0, lng: 0 });

  const {
    layerLevel,
    activeMobile,
    anySpeed,
    cellTowers,
    internetUsers,
    year,
  } = sliderData;

  const dataPerCountry = existingData.filter(({ country }) => country.toLowerCase() === countryName);

  const dataPerAdmin = dataPerCountry.find((data) => data?.layerLevel === layerLevel);

  const data = dataPerAdmin?.data;

  const cellTowerCount = data?.features?.map(({ properties }) => Number(properties?.cell_tower_count)) || 0;

  // Remove undefined values from the array
  const filteredCellTowerCount =
    cellTowerCount &&
    cellTowerCount.filter((count) => typeof count === "number");

  // Calculate the maximum and minimum values
  useEffect(() => {
    if (filteredCellTowerCount) {
      const maxCellTowerCount = Math.max(...filteredCellTowerCount);
      const minCellTowerCount = Math.min(...filteredCellTowerCount);
      setCellTowerRange([minCellTowerCount, maxCellTowerCount]);
    }
    // eslint-disable-next-line
  }, [cellTowerCount?.length]);

  const onEachCountry = useCallback((feature, layer) => {
      const { properties } = feature;

      const getDataValue = (value) => {
        const roundedValue = parseFloat(Number(value)?.toFixed(3) || 0);
        return `${roundedValue || 0}%`;
      };

      const getProperty = (property) => {
        const indicatorPerYear = `${layerLevel}${property}${year}`;
        return parseFloat(Number(properties[indicatorPerYear]).toFixed(2));
      };

      const dataFixedSpeed = getProperty(FIXED_SPEED);
      const dataActiveMobile = getProperty(ACTIVE_MOBILE);
      const dataInternetUsers = getProperty(INTERNET_USERS);

      const criteriaMetCount =
        (properties[CELL_TOWER_COUNT] >= cellTowers[0] && properties[CELL_TOWER_COUNT] <= cellTowers[1]
          ? 1
          : 0) +
        (dataFixedSpeed >= anySpeed[0] && dataFixedSpeed <= anySpeed[1]
          ? 1
          : 0) +
        (dataActiveMobile >= activeMobile[0] && dataActiveMobile <= activeMobile[1]
          ? 1
          : 0) +
        (dataInternetUsers >= internetUsers[0] && dataInternetUsers <= internetUsers[1]
          ? 1
          : 0);

      const getOpacity = () => {
        const initialOpacity = 0.25;
        if (criteriaMetCount === 0) {
          return null;
        } else if (criteriaMetCount === 1) {
          return initialOpacity;
        } else if (criteriaMetCount === 2) {
          return initialOpacity * 2;
        } else if (criteriaMetCount === 3) {
          return initialOpacity * 3;
        } else if (criteriaMetCount === 4) {
          return initialOpacity * 4;
        } else {
          return null;
        }
      };

      const getFillColor = () => {
        if (criteriaMetCount === 0) {
          return darkModeColor[0];
        } else if (criteriaMetCount === 1) {
          return darkModeColor[1];
        } else if (criteriaMetCount === 2) {
          return darkModeColor[2];
        } else if (criteriaMetCount === 3) {
          return darkModeColor[3];
        } else if (criteriaMetCount === 4) {
          return darkModeColor[4];
        } else {
          return null;
        }
      };

      const opacity = getOpacity();

      const fillColor = getFillColor();

      const darkModeOpacity = opacity === null ? 0.85 : 0.85;
      const lightModeOpacity = opacity === null ? 0.5 : opacity;

      const layerStyle = {
        fillColor: opacity === null ? "#FFF" : darkMode ? fillColor : "#1D76D2",
        weight: 1,
        color: darkMode ? "#393939" : "gray",
        opacity: 1,
        fillOpacity: darkMode ? darkModeOpacity : lightModeOpacity,
      };

      layer.setStyle(layerStyle);

      const layerName = getLayer({
        layerLevel,
        param1: properties.adm2_pt,
        param2: properties.adm1_pt,
      });

      const tooltipContentJSX = (
        <div className="tooltip drawer-map-tootip">
          <Typography component="h4" className="title">
            {layerName}
          </Typography>
          {drawerData.map((indicator) => {
            const { value, name } = indicator;
            const indicatorPerYear = properties[`${layerLevel}${value}${year}`];
            return (
              <div key={value}>
                <span>
                  {name}:{" "}
                  <b>
                    {value === "cell_tower_count"
                      ? properties["cell_tower_count"]
                      : getDataValue(indicatorPerYear)}
                  </b>
                </span>
                <br />
              </div>
            );
          })}
        </div>
      );

      const tooltipContentString = renderToString(tooltipContentJSX);

      controlLayer({ layer, layerStyle, tooltipContentString, setTooltipContent, setTooltipPosition });
    },
    [
      layerLevel,
      setTooltipPosition,
      activeMobile,
      anySpeed,
      cellTowers,
      internetUsers,
      year,
      darkMode,
    ]
  );

  // Using customized tooltip so that the tooltip is always above the layers
  const tooltip = L.tooltip({
    permanent: true,
    opacity: 1,
    className: "drawer-map-tooltip",
  });

  useEffect(() => {
    if (tooltipContent) {
      const latLng = [tooltipPosition.lat, tooltipPosition.lng];
      tooltip.setContent(tooltipContent);
      tooltip.setLatLng(latLng).addTo(map);
    } else {
      map?.removeLayer(tooltip);
    }

    return () => map?.removeLayer(tooltip);
  }, [map, tooltipContent, tooltipPosition, tooltip]);

  return (
    <Pane name="drawer-map">
      {pathname !== REPORT && (
        <GeoJSON
          key={JSON.stringify({
            ...sliderData,
            darkMode,
            dataLength: data?.features?.length,
          })}
          data={data}
          onEachFeature={onEachCountry}
        />
      )}
    </Pane>
  );
};

export default Drawer;
