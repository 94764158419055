import { Typography, Box, List, ListItem } from "@mui/material";
import { useContext } from "react";
import { Context } from "../../../../../GlobalContext";
import { electricityLegend, getElectricityColor } from "../../../../../utils/legends";
import { countriesString } from "../../../../../utils/strings";

const Electricity = () => {
  const { countryName } = useContext(Context);
  const legendPerCountry = electricityLegend.find(
    (legend) => legend?.country?.toLowerCase() === countryName || legend?.country?.toLowerCase() === "mozambique"
  );
  return (
    <Box className="electricity-line legend-item">
      <Typography component="h4">Electricity lines</Typography>
      <List className="list">
        {legendPerCountry.legend.map((range, index) => (
          <ListItem key={index} className="list-item">
            <span
              className="electricity-line-status-color long-bar"
              style={{
                opacity: 1,
                backgroundColor: getElectricityColor({
                  voltage_kV: legendPerCountry.color.sort()[index],
                }),
              }}
            />
            {range}
          </ListItem>
        ))}
        {countryName === countriesString.MOZAMBIQUE.toLowerCase() && (
          <ListItem className="list-item">
            <span
              className="electricity-line-status-color long-bar"
              style={{
                opacity: 1,
                backgroundColor: "#B04A7E",
              }}
            />
            DC
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default Electricity;
