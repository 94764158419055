import { createElement } from "react";
import { Box } from "@mui/material";
import "./legend.scss";
import {
  FIBRE_OPTIC_LINES,
  ELECTRICITY_GRID,
  ROAD_NETWORK,
  HEALTHCARE,
  CELL_TOWERS,
  EDUCATION,
  SIGNIFICANT_VOLCANIC_ERUPTION,
  SIGNIFICANT_EARTHQUAKES,
  TSUNAMI_EVENT,
  WIND,
  EARTHQUAKES_MAGNITUDE,
  CELL_TOWER_ICON,
  HOSPITAL_ICON,
  SCHOOL_ICON_URL,
} from "../../../../utils/strings";

import FibreOpticLines from "./Contents/FibreOpticLines";
import Electricity from "./Contents/Electricity";
import Roads from "./Contents/Road";
import PointBasedLayer from "./Contents/PointBasedLayer";
import SignificatVolcanic from "./Contents/SignificatVolcanic";
import SignificatEarthquakes from "./Contents/SignificantEarthquakes";
import TsunamiSourceEvents from "./Contents/TsunamiSourceEvents";
import EarthquakesMagnitude from "./Contents/EarthquakesMagnitude";
import WindSpeed from "./Contents/WindSpeed";

const Legend = ({ selectedDatasets, isShownLegend, legendContainerRef, countryName }) => {

  const renderPointBasedLayer = (name, icon, value) => (
    selectedDatasets?.includes(value) && <PointBasedLayer name={name} icon={icon} value={value} />
  );

  const renderDataset = (datasetComponent) => (
    selectedDatasets?.includes(datasetComponent.datasetType) && (
      createElement(datasetComponent.component, { countryName })
    )
  );

  return (
    <>
      {isShownLegend && (
        <Box className="legend bg-layers-legend" ref={legendContainerRef}>
          <Box className="bg-layers-legend-wrapper">
            {renderPointBasedLayer("Health facility", HOSPITAL_ICON, HEALTHCARE)}
            {renderPointBasedLayer("Education facility", SCHOOL_ICON_URL, EDUCATION)}
            {renderPointBasedLayer("Cell tower", CELL_TOWER_ICON, CELL_TOWERS)}
            {renderDataset({ datasetType: FIBRE_OPTIC_LINES, component: FibreOpticLines })}
            {renderDataset({ datasetType: ELECTRICITY_GRID, component: Electricity })}
            {renderDataset({ datasetType: ROAD_NETWORK, component: Roads })}
            {renderDataset({ datasetType: SIGNIFICANT_VOLCANIC_ERUPTION, component: SignificatVolcanic })}
            {renderDataset({ datasetType: EARTHQUAKES_MAGNITUDE, component: EarthquakesMagnitude })}
            {renderDataset({ datasetType: SIGNIFICANT_EARTHQUAKES, component: SignificatEarthquakes })}
            {renderDataset({ datasetType: TSUNAMI_EVENT, component: TsunamiSourceEvents })}
            {renderDataset({ datasetType: WIND, component: WindSpeed })}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Legend;