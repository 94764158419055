import { FormControl, RadioGroup, Typography } from "@mui/material";
import RadioButton from "../RadioButton/RadioButton";
import { className, titleCase } from "../../../utils/strings";

const DateAdminForm = ({
  id,
  label,
  name,
  options,
  value,
  containerClassName = "",
  defaultChecked,
  action
}) => {
  return (
    <FormControl className={containerClassName}>
      <Typography component="span" className={className.CONTROLLER_TITLE}>{label}</Typography>
      <RadioGroup aria-label={name} name={name} value={value} onChange={action}>
        {options.map((value) => (
          <RadioButton
            id={`${value}-${id}`}
            isParent={true}
            key={value}
            value={value}
            label={titleCase(value)}
            defaultChecked={defaultChecked}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default DateAdminForm;
