import { useContext, useEffect } from "react";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { Box } from "@mui/system";

import { Context } from "../../GlobalContext";

import { attribution, mapUrl } from "../../utils/strings";

import { default as BackgroundLayers } from "../../components/Layers/BackgroundLayers/BackgroundLayers";
import { default as BackgroundLayerPanel } from "../../components/Panels/BackgroundLayer/BackgroundLayer";
import { default as IndicatorPanel } from "../../components/Panels/Indicators/Indicators";
import { default as DrawerLayer } from "../../components/Layers/Drawer/Drawer";
import Drawer from "../../components/Panels/Drawer/Drawer";

const Home = () => {
  const { setMap, position, isOpenedDrawer, setCacheCleaner } = useContext(Context);

  const { lat, lon } = position;

  useEffect(() => {
    setCacheCleaner(false);
    // eslint-disable-next-line
  }, []);

  return (
    <Box className="map-container">
      <Drawer />

      <MapContainer
        center={[lat, lon]}
        zoom={6}
        scrollWheelZoom={true}
        ref={setMap}
        zoomControl={false}
      >
        <TileLayer {...{ attribution, url: mapUrl }} />

        <BackgroundLayerPanel />

        <BackgroundLayers />

        <IndicatorPanel />

        {isOpenedDrawer && <DrawerLayer />}

        <ZoomControl position="bottomleft" />
      </MapContainer>
    </Box>
  );
};

export default Home;
