import { Button, Tooltip } from "@mui/material";
import "./open-panel-button.scss";

const OpenPanelButton = ({ placement, title, setIsOpenedPanel, Icon }) => {
  return (
    <Tooltip title={title} placement={placement}>
      <Button
        onClick={() => setIsOpenedPanel(true)}
        className="open-panel-button"
      >
        <Icon />
      </Button>
    </Tooltip>
  );
};

export default OpenPanelButton;
