import { useEffect, useRef, useState, useContext } from "react";
import { Badge, Box, FormGroup } from "@mui/material";
import { DatasetOutlined } from "@mui/icons-material";
import Control from "react-leaflet-custom-control";

// CSS
import "leaflet/dist/leaflet.css";
import "./background-layer.scss";

// Utilities
import { Context } from "../../../GlobalContext";
import { initialBackgroundDatasets } from "../../../utils/misc";

import {
  CELL_TOWERS,
  EARTHQUAKES_MAGNITUDE,
  EDUCATION,
  ELECTRICITY_GRID,
  FIBRE_OPTIC_LINES,
  HEALTHCARE,
  ROAD_NETWORK,
  SIGNIFICANT_EARTHQUAKES,
  SIGNIFICANT_VOLCANIC_ERUPTION,
  TSUNAMI_EVENT,
  WIND,
} from "../../../utils/strings";

// Components
import ControllerContainer from "../../PanelContainer/PanelContainer";
import BackgroundLayersInfo from "./Info/BackgroundLayerInfo";
import PanelHeader from "../../PanelHeader/PanelHeader";
import OpenPanelButton from "../../OpenPanelButton/OpenPanelButton";
import CheckboxGroup from "../../CheckboxGroup/CheckboxGroup";
import CheckboxGroupContent from "./CheckboxGroupContent";
import Legend from "../Legends/BackgroundLayers/Legend";

const BackgroundLayer = () => {
  // Panel containers Refs
  const controllerContainerRef = useRef();
  const legendContainerRef = useRef();
  const infoContainerRef = useRef();

  // Scroll states
  const [containerScrollPosition, setScrollPosition] = useState(controllerContainerRef?.current?.scrollTop || 0);
  const [legendScrollPosition, setLegendScrollPosition] = useState(legendContainerRef?.current?.scrollTop || 0);
  const [infoScrollPosition, setInfoScrollPosition] = useState(infoContainerRef?.current?.scrollTop || 0);

  // Other states
  const {isShownPopulationInfo, selectedDatasets, setSelectedDatasets, countryName } = useContext(Context);
  const screenWidth = window.innerWidth;
  const [isOpenedPanel, setIsOpenedPanel] = useState(screenWidth > 795 ? true : false);

  const items = initialBackgroundDatasets.map(({ items }) => items).flat();

  const selectedItems = items.filter(({ value }) => selectedDatasets.includes(value));

  const isAppliedMobileStyle = selectedItems.length > 0 && screenWidth < 795;

  const hasDatasets = (key) => selectedDatasets.includes(key);

  const isShownLegend =
    hasDatasets(WIND) ||
    hasDatasets(EARTHQUAKES_MAGNITUDE) ||
    hasDatasets(TSUNAMI_EVENT) ||
    hasDatasets(SIGNIFICANT_EARTHQUAKES) ||
    hasDatasets(SIGNIFICANT_VOLCANIC_ERUPTION) ||
    hasDatasets(FIBRE_OPTIC_LINES) ||
    hasDatasets(ELECTRICITY_GRID) ||
    hasDatasets(ROAD_NETWORK) ||
    hasDatasets(EDUCATION) ||
    hasDatasets(CELL_TOWERS) ||
    hasDatasets(HEALTHCARE);

  const getSelectedGroups = (value) => {
    if (!hasDatasets(value)) {
      setSelectedDatasets([...selectedDatasets, value]);
    } else {
      const category = initialBackgroundDatasets.find((dataset) => dataset.value === value);
      const dataItems = category?.items.map(({ value }) => value) || [];
      const removedGroup = selectedDatasets.filter((groups) => !groups.includes(value));

      if (dataItems.length > 0) {

        // Remove category and items
        const removeAllData = removedGroup.filter((group) => !dataItems.includes(group));
        setSelectedDatasets(removeAllData);
      } else {
        setSelectedDatasets(removedGroup);
      }
    }
  };

  const getScrollPostion = () => {
    setLegendScrollPosition(legendContainerRef?.current?.scrollTop);
    setScrollPosition(controllerContainerRef?.current?.scrollTop);
    setInfoScrollPosition(infoContainerRef?.current?.scrollTop);
  };

  // Keep controller container scroll position
  useEffect(() => {
    if (controllerContainerRef.current) {
      controllerContainerRef.current.scrollTop = containerScrollPosition;
    }
  }, [containerScrollPosition, selectedDatasets, isShownPopulationInfo]);

  // Keep legend scroll position
  useEffect(() => {
    if (legendContainerRef.current) {
      legendContainerRef.current.scrollTop = legendScrollPosition;
    }
  }, [legendScrollPosition, selectedDatasets, isShownPopulationInfo]);

  // Keep info card scroll position
  useEffect(() => {
    if (infoContainerRef.current) {
      infoContainerRef.current.scrollTop = infoScrollPosition;
    }
  }, [infoScrollPosition, selectedDatasets, isShownPopulationInfo]);

  return (
    <Control position="bottomleft">
      <ControllerContainer className={`bg-layers-controller ${isOpenedPanel ? "opened-controller" : "closed-controller"}`}>
        {isOpenedPanel && (
          <PanelHeader
            title={
              <Box className="bg-layers-header-wrapper">
                <span className="bg-layers-title">
                  Background Layers
                </span>
                {isShownLegend && (
                  <span className="bg-layers-legend-title">
                    Legends
                  </span>
                )}
              </Box>
            }
            setIsOpenedPanel={setIsOpenedPanel}
          />
        )}

        {!isOpenedPanel && (
          <Badge color="secondary" badgeContent={selectedDatasets.length}>
            <OpenPanelButton
              placement="right"
              title="Display background layers"
              Icon={DatasetOutlined}
              setIsOpenedPanel={() => setIsOpenedPanel(true)}
            />
          </Badge>
        )}

        {/* Controller */}
        <FormGroup
          className="bg-layers-wrapper"
          ref={controllerContainerRef}
          style={{ marginBottom: isAppliedMobileStyle ? "8px" : 0 }}
        >
          {initialBackgroundDatasets.map(({ id, category, value, items }) => {
            const itemsPerCategory = items.map(({ value }) => value);

            const selectedItems = itemsPerCategory
              .map((item) => (selectedDatasets.includes(item) ? item : null))
              .filter((item) => item !== null);

            const isIndeterminate = selectedItems?.length === 2 || selectedDatasets.includes(value);
            return (
              <CheckboxGroup
                key={id}
                value={value}
                label={category}
                action={(event) => {
                  getSelectedGroups(event.target.value);
                  getScrollPostion();
                }}
                checked={selectedItems?.length === 1}
                indeterminate={isIndeterminate}
                children={
                  selectedDatasets.includes(value) &&
                  items.map((item) => {
                    const { id, value, name, data } = item;
                    return (
                      <CheckboxGroupContent
                        key={id}
                        value={value}
                        name={name}
                        data={data}
                        selectedDatasets={selectedDatasets}
                        getScrollPostion={getScrollPostion}
                        getSelectedGroups={getSelectedGroups}
                      />
                    );
                  })
                }
              />
            );
          })}
        </FormGroup>

        {/* Legend  */}
        {isOpenedPanel && (
          <Box
            className="mobile-legend-wrapper"
            style={{ paddingTop: isAppliedMobileStyle ? "38px" : 0 }}
          >
            {isAppliedMobileStyle && (
              <PanelHeader
                title={
                  <Box className="mobile-legend-header-wrapper">
                    {isShownLegend && <span>Legends</span>}
                  </Box>
                }
                setIsOpenedPanel={setIsOpenedPanel}
              />
            )}

            <Legend
              countryName={countryName}
              selectedDatasets={selectedDatasets}
              isShownLegend={isShownLegend}
              legendContainerRef={legendContainerRef}
            />
          </Box>
        )}
      </ControllerContainer>

      {/* Dataset information */}
      <BackgroundLayersInfo
        selectedDatasets={selectedDatasets}
        isShownPopulationInfo={isShownPopulationInfo}
        infoContainerRef={infoContainerRef}
        countryName={countryName}
      />
    </Control>
  );
};

export default BackgroundLayer;
