import { Box, List, ListItem, Typography } from "@mui/material";
import { naturalHazardLegend } from "../../../../../utils/legends";
import { ReactComponent as Triangle } from "../../../../../assets/triangle.svg";

const SignificatVolcanic = () => {
  return (
    <Box className="natural-hazard legend-item">
      <Typography component="h4" className="legend-title">
        Significant Volcanic Eruption Effects
      </Typography>
      <List className="list">
        {naturalHazardLegend.map(({ color, label }, index) => (
          <ListItem key={index} className="list-item ">
            <div className="triangle legend-icon-wrapper">
              <Triangle className={`status-color ${color}`} />
            </div>
            {label}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SignificatVolcanic;
