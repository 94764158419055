import { countriesString } from "./strings";

const {
  CONGO,
  KAZAKHSTAN,
  KYRGYZSTAN,
  MOZAMBIQUE,
  MONGOLIA,
  NIGERIA,
  TAJIKISTAN,
  UZBEKISTAN,
} = countriesString;

export const roadLegends = [
  {
    country: CONGO,
    legend: [
      "unclassified",
      "primary",
      "secondary",
      "secondary_link",
      "tertiary",
      "trunk",
      "trunk_link",
      "motorway_junction",
    ],
  },
  {
    country: KAZAKHSTAN,
    legend: [
      "abandoned",
      "bridleway",
      "construction",
      "cycleway",
      "disused",
      "emergency_access_point",
      "footway",
      "living_street",
      "motorway",
      "motorway_link",
      "path",
      "pedestrian",
      "primary",
      "primary_link",
      "proposed",
      "raceway",
      "residential",
      "rest_area",
      "road",
      "secondary",
      "secondary_link",
      "service",
      "services",
      "steps",
      "tertiary",
      "tertiary_link",
      "track",
      "trunk",
      "trunk_link",
      "unclassified",
      "provincial",
    ],
  },
  {
    country: KYRGYZSTAN,
    legend: [
      "primary",
      "secondary",
      "tertiary",
      "trunk",
      "residential",
      "secondary_link",
      "primary_link",
      "trunk_link",
      "unclassified",
      "track",
      "path",
      "footway",
      "service",
      "living_street",
      "tertiary_link",
      "steps",
      "pedestrian",
      "road",
      "raceway",
      "bridleway",
      "abandoned",
      "escape",
      "proposed",
      "services",
      "construction",
      "cycleway",
      "rest_area",
      "crossing",
    ],
  },
  {
    country: MONGOLIA,
    legend: [
      "trunk",
      "primary",
      "tertiary",
      "unclassified",
      "secondary",
      "footway",
      "track",
      "residential",
      "service",
      "path",
      "road",
      "steps",
      "trunk_link",
      "primary_link",
      "tertiary_link",
      "living_street",
      "secondary_link",
      "pedestrian",
      "cycleway",
      "rest_area",
      "construction",
      "raceway",
      "services",
      "motorway",
      "motorway_link",
      "elevator",
    ],
  },
  {
    country: MOZAMBIQUE,
    legend: [
      "tertiary",
      "primary",
      "trunk",
      "secondary",
      "primary_link",
      "trunk_link",
      "secondary_link",
      "tertiary_link",
    ],
  },
  {
    country: NIGERIA,
    legend: [
      "tertiary",
      "primary",
      "trunk",
      "secondary",
      "primary_link",
      "trunk_link",
      "secondary_link",
      "tertiary_link",
    ],
  },
  {
    country: TAJIKISTAN,
    legend: [
      "primary",
      "trunk",
      "track",
      "tertiary",
      "secondary",
      "residential",
      "secondary_link",
      "service",
      "trunk_link",
      "unclassified",
      "construction",
      "footway",
      "pedestrian",
      "tertiary_link",
      "primary_link",
      "living_street",
      "road",
      "path",
      "steps",
      "bridleway",
      "rest_area",
      "emergency_access_point",
      "raceway",
      "cycleway",
    ],
  },
  {
    country: UZBEKISTAN,
    legend: [
      "primary",
      "trunk",
      "track",
      "tertiary",
      "secondary",
      "residential",
      "secondary_link",
      "service",
      "trunk_link",
      "unclassified",
      "construction",
      "footway",
      "pedestrian",
      "tertiary_link",
      "primary_link",
      "living_street",
      "road",
      "path",
      "steps",
      "bridleway",
      "rest_area",
      "emergency_access_point",
      "raceway",
      "cycleway",
    ],
  },
];

export const electricityLegend = [
  {
    country: CONGO,
    legend: ["70kV", "132kV", "220kV", "400kV", "500kV"],
    color: [70, 132, 220, 400, 500],
  },
  {
    country: KAZAKHSTAN,
    legend: ["220kV", "500kV", "1150kV"],
    color: [1150, 220, 500],
  },
  {
    country: KYRGYZSTAN,
    legend: ["Unknown", "110kV", "220kV", "500kV"],
    color: [0, 220, 500, 110],
  },
  {
    country: MONGOLIA,
    legend: ["Unknown", "110kV", "220kV"],
    color: [0, 220, 110],
  },
  {
    country: MOZAMBIQUE,
    legend: ["110kV", "132-150kV", "220kV", "300-330kV", "380-400kV", "500kV"],
    color: [110, 330, 220, 132, 400, 500],
  },
  {
    country: NIGERIA,
    legend: ["0", "11kV", "30kV", "33kV", "132kV", "330kV"],
    color: [0, 11, 30, 33, 132, 330],
  },
  {
    country: TAJIKISTAN,
    legend: ["Unknown", "110kV", "132-150kV", "220kV", "500kV"],
    color: [0, 220, 500, 110, 138],
  },
  {
    country: UZBEKISTAN,
    legend: ["220kV", "500kV"],
    color: [220, 500],
  },
];

export const naturalHazardLegend = [
  { color: "green", label: "No deaths or unknown", hex: "#87E616" },
  { color: "blue", label: "1 to 50 deaths", hex: "#1D76D2" },
  { color: "yellow", label: "51 to 100 deaths", hex: "#F6DA2A" },
  { color: "orange", label: "101 to 1000 deaths", hex: "#E3A932" },
  { color: "red", label: "1000 or above 1000 deaths", hex: "#D74606" },
];

export const windWpeedLegend = [
  { color: "#fff", label: "" },
  { color: "#fff", label: "0" },
  { color: "#fff", label: "" },
  { color: "#000080", label: "" },
  { color: "#0000ff", label: "" },
  { color: "#0000ff", label: "1.88" },
  { color: "#0080ff", label: "" },
  { color: "#0080ff", label: "3.75" },
  { color: "#00ffff", label: "" },
  { color: "#00ffff", label: "5.63" },
  { color: "#80ff80", label: "" },
  { color: "#80ff80", label: "7.50" },
  { color: "#ffff00", label: "" },
  { color: "#ffff00", label: "9.38" },
  { color: "#ff8000", label: "" },
  { color: "#ff8000", label: "11.25" },
  { color: "#ff0000", label: "" },
  { color: "#ff0000", label: "13.13" },
  { color: "#800000", label: "" },
  { color: "#800000", label: "15.00" },
];

export const getLegend = ({ indicator, countryName }) => {
  if (countryName === "uzbekistan" || countryName === "nigeria") {
    return indicator;
  } else if (indicator?.includes("mobile")) {
    return "mobile";
  } else if (indicator?.includes("users")) {
    return "internetUsers";
  } else if (indicator?.includes("_gni")) {
    return "grossIncome";
  } else if (indicator?.includes("_pop")) {
    return "population";
  } else {
    return indicator;
  }
};

export const getRoadColor = (highway) => {
  if (highway?.includes("primary")) {
    return "#F8D6A1";
  }
  if (highway?.includes("secondary")) {
    return "#F5F6BB";
  }
  if (highway?.includes("trunk")) {
    return "#F2BFAC";
  }
  if (highway?.includes("motorway")) {
    return "#EA90A0";
  }
  if (highway?.includes("track")) {
    return "#AC8327";
  }
  if (
    highway?.includes("residential") ||
    highway === "service" ||
    highway?.includes("tertiary") ||
    highway?.includes("unclassified")
  ) {
    return "#FEFEFE";
  }
  if (highway?.includes("path")) {
    return "#EEA7A0";
  }
  if (highway?.includes("road")) {
    return "#DDDDDD";
  }
  if (highway?.includes("steps")) {
    return "#E87B6F";
  }

  if (highway?.includes("footway")) {
    return "#EEA59D";
  }
  if (highway?.includes("living_street")) {
    return "#ECEEED";
  }
  if (highway?.includes("pedestrian")) {
    return "#DDDCE8";
  }
  if (highway?.includes("cycleway")) {
    return "#596CFB";
  }
  if (highway?.includes("rest_area")) {
    return "#8CA0BE";
  }
  if (highway?.includes("construction") || highway?.includes("provincial")) {
    return "#AAAAAA";
  }
  if (highway?.includes("raceway")) {
    return "#F1BEC9";
  }
  if (highway === "services") {
    return "#8BA6B7";
  }
  if (highway?.includes("elevator") || highway?.includes("bus_stop")) {
    return "#3A93DA";
  }
  if (
    highway?.includes("proposed") ||
    highway?.includes("yes") ||
    highway?.includes("corridor") ||
    highway?.includes("abandoned") ||
    highway?.includes("disused") ||
    highway?.includes("emergency_access_point")
  ) {
    return "#FFF";
  }

  if (highway?.includes("bridleway")) {
    return "#619D46";
  }
};

export const dashedRoad = (highway) => {
  if (highway.includes("track")) {
    return { line: "8, 8", weight: 2 };
  } else if (
    highway.includes("footway") ||
    highway.includes("path") ||
    highway.includes("sidewalk") ||
    highway.includes("crossing") ||
    highway.includes("cycleway")
  ) {
    return { line: "0.5, 5", weight: 2 };
  } else if (highway.includes("bridleway")) {
    return { line: "5, 8", weight: 2 };
  } else if (highway.includes("steps")) {
    return { line: "0.5, 6", weight: 5 };
  } else if (highway.includes("_link")) {
    return { line: "", weight: 15 };
  } else {
    return { line: "", weight: 4 };
  }
};

export const getElectricityColor = ({ voltage_kV, type }) => {
  const voltage = Number(voltage_kV);
  if (type?.includes("DC")) {
    return "#B04A7E";
  } else if (voltage > 1 && voltage <= 110) {
    return "#000000";
  } else if (voltage >= 132 && voltage <= 150) {
    return "#000000";
  } else if (voltage === 220) {
    return "#3C6B0A";
  } else if (voltage >= 132 && voltage <= 150) {
    return "#E08C16";
  } else if (voltage >= 300 && voltage <= 330) {
    return "#D48518";
  } else if (voltage >= 380 && voltage <= 400) {
    return "#AA3603";
  } else if (voltage >= 500) {
    return "#204880";
  } else {
    return "grey";
  }
};
