export const getFixedBroadband = (getValue) => {
  return [
    {
      data: getValue("fixed_256_2", "", 2020),
      label: "2020 - 256 kbit/s to 2 Mbit/s",
    },
    {
      data: getValue("fixed_2_10", "", 2020),
      label: "2020 - 2 Mbit/s to 10 Mbit/s",
    },
    {
      data: getValue("fixed_above10", "", 2020),
      label: "2020 - Above 10 Mbit/s",
    },
    {
      data: getValue("fixed_256_2", "", 2030),
      label: "2030 - 256 kbit/s to 2 Mbit/s",
    },
    {
      data: getValue("fixe d_2_10", "", 2030),
      label: "2030 - 2 Mbit/s to 10 Mbit/s",
    },
    {
      data: getValue("fixed_above10", "", 2030),
      label: "2030 - Above 10 Mbit/s",
    },
  ];
};

export const getFixedMobilePerUrbanization = (getSubValue) => {
  return [
    {
      data: getSubValue("fixed", "rural", 2020),
      label: "2020 - Rural fixed broadband subscriptions",
      color: "#FAD876",
    },
    {
      data: getSubValue("fixed", "urban", 2020),
      label: "2020 - Urban fixed broadband subscriptions",
      color: "#E44C19",
    },
    {
      data: getSubValue("mobile", "rural", 2020),
      label: "2020 - Rural mobile broadband subscriptions",
      color: "#C7E9B4",
    },
    {
      data: getSubValue("mobile", "urban", 2020),
      label: "2020 - Urban mobile broadband subscriptions",
      color: "#225EA8",
    },
    {
      data: getSubValue("fixed", "rural", 2030),
      label: "2030 - Rural fixed broadband subscriptions",
      color: "#FAD876",
    },
    {
      data: getSubValue("fixed", "urban", 2020),
      label: "2030 - Urban fixed broadband subscriptions",
      color: "#E44C19",
    },
    {
      data: getSubValue("mobile", "rural", 2030),
      label: "2030 - Rural mobile broadband subscriptions",
      color: "#C7E9B4",
    },
    {
      data: getSubValue("mobile", "urban", 2030),
      label: "2030 - Urban mobile broadband subscriptions",
      color: "#225EA8",
    },
  ];
};

export const getUsersMobilePerGender = (getSubValue) => {
  return [
    {
      data: getSubValue("users", "female", 2020),
      label: "2020 - Female internet users",
      color: "#fad876",
    },
    {
      data: getSubValue("users", "male", 2020),
      label: "2020 - Male internet users",
      color: "#e44c19",
    },
    {
      data: getSubValue("mobile", "female", 2020),
      label: "2020 - Female mobile broadband subscriptions",
      color: "#c7e9b4",
    },
    {
      data: getSubValue("mobile", "male", 2020),
      label: "2020 - Male mobile broadband subscriptions",
      color: "#225ea8",
    },
    {
      data: getSubValue("users", "female", 2030),
      label: "2030 - Female internet users",
      color: "#fad876",
    },
    {
      data: getSubValue("users", "male", 2030),
      label: "2030 - Male internet users",
      color: "#e44c19",
    },
    {
      data: getSubValue("mobile", "female", 2030),
      label: "2030 - Female mobile broadband subscriptions",
      color: "#c7e9b4",
    },
    {
      data: getSubValue("mobile", "male", 2030),
      label: "2030 - Male mobile broadband subscriptions",
      color: "#225ea8",
    },
  ];
};

export const getAffordabilityByCategory = (
  data,
  category,
  getAffordability
) => {
  return [
    {
      data: getAffordability(data, "cablefixedmin", category, 2020),
      label: "2020 - Minimum Fixed Plan",
      value: "Minimum",
    },
    {
      data: getAffordability(data, "cablefixedavg", category, 2020),
      label: "2020 - Average Fixed Plan",
      value: "Average",
    },
    {
      data: getAffordability(data, "cablefixedmax", category, 2020),
      label: "2020 - Maximum Fixed Plan",
      value: "Maximum",
    },
    {
      data: getAffordability(data, "itu_mobile1gb", category, 2020),
      label: "2020 - 1Gb Mobile Plan",
      value: "1Gb",
      color: "#8c6dc1",
    },
    {
      data: getAffordability(data, "itu_mobile5gb", category, 2020),
      label: "2020 - 5Gb Mobile Plan",
      value: "5Gb",
      color: "#5d7be4",
    },
    {
      data: getAffordability(data, "itu_mobile10gb", category, 2020),
      label: "2020 - 10Gb Mobile Plan",
      value: "10Gb",
      color: "#4a63b2",
    },
    {
      data: getAffordability(data, "cablefixedmin", category, 2030),
      label: "2030 - Minimum Fixed Plan",
      color: "#ffc300",
      value: "Minimum",
    },
    {
      data: getAffordability(data, "cablefixedavg", category, 2030),
      label: "2030 - Average Fixed Plan",
      color: "#ff5733",
      value: "Average",
    },
    {
      data: getAffordability(data, "cablefixedmax", category, 2030),
      label: "2030 - Maximum Fixed Plan",
      color: "#ab1e00",
      value: "Maximum",
    },
    {
      data: getAffordability(data, "itu_mobile1gb", category, 2030),
      label: "2030 - 1Gb Mobile Plan",
      value: "1Gb",
    },
    {
      data: getAffordability(data, "itu_mobile5gb", category, 2030),
      label: "2030 - 5Gb Mobile Plan",
      value: "5Gb",
    },
    {
      data: getAffordability(data, "itu_mobile10gb", category, 2030),
      label: "2030 - 10Gb Mobile Plan",
      value: "10Gb",
    },
  ];
};
