import { createContext, useEffect, useState } from "react";
import {
  baseUrl,
  BROADBAND_INFRASTRUCTURES,
  CELL_TOWERS,
  DRC,
  FIBRE_OPTIC_LINES,
  WIND,
} from "./utils/strings";
import { checkExistingData, fetchData } from "./utils/api";

export const Context = createContext(null);

const ContextProvider = ({ children }) => {
  const [map, setMap] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  const initialCountryName = "mozambique";
  const initialSelectedDatasets = [
    BROADBAND_INFRASTRUCTURES,
    FIBRE_OPTIC_LINES,
    CELL_TOWERS,
  ];
  const [countries, setCountries] = useState([]);
  const [countryName, setCountryName] = useState(initialCountryName);
  const [isShownPopulationInfo, setIsShownPopulationInfo] = useState(false);
  const [indicatorData, setIndicatorData] = useState({
    layerLevel: "",
    year: "",
    indicator: "",
    urbanization: "",
    gender: "",
    age: "",
    affordability: "",
    affordabilityCategory: "",
  });
  const [selectedDatasets, setSelectedDatasets] = useState(initialSelectedDatasets);
  const [loading, setLoading] = useState([]);
  const [isShownModal, setIsShownModal] = useState(false);
  const [isOpenedDrawer, setIsOpenedDrawer] = useState(false);
  const [message, setMessage] = useState("");
  const [position, setPosition] = useState({ lat: -19.3, lon: 34.91 });
  const [existingData, setExistingData] = useState([]);
  const [cellTowerRange, setCellTowerRange] = useState([0, 1000]);
  const [ituDataList, setItuDataList] = useState([]);
  const [cacheCleaner, setCacheCleaner] = useState(false);
  const [isLoadPdf, setIsLoadPdf] = useState(false);

  const newCountryName = countryName === DRC ? "drc" : countryName;

  const sliderDefaultLayer = ituDataList?.includes(`${newCountryName}_itu_district`) ? "district" : "province";

  const [sliderData, setSliderData] = useState({
    year: "2020",
    layerLevel: sliderDefaultLayer,
    activeMobile: [5, 30],
    internetUsers: [0.1, 0.3],
    anySpeed: [0.28, 0.56],
    cellTowers: [2261, 4662],
  });
  const [existingLegends, setExistingLegends] = useState([]);
  const [affordabilitySelection, setAffordabilitySelection] = useState([]);
  const { indicator, layerLevel, year } = indicatorData;

  const affordAdmin = layerLevel === "province" ? "provinces" : "district";

  const layerLevelPerCountry = isOpenedDrawer ? sliderData.layerLevel : layerLevel;

  const ituLegendData = existingLegends?.find((legend) => {
    return legend?.name === `${newCountryName}-${layerLevelPerCountry}`;
  });

  const ituLegends = ituLegendData?.data;

  const getAffordLegends = () => {
    const affordCategory = indicatorData?.affordabilityCategory?.split("_")?.join(".");
    const ituAffordLegendData = existingLegends?.find((legend) => {
      return (legend?.name === `${newCountryName}-${affordAdmin}-${affordCategory}`);
    });

    return ituAffordLegendData?.data;
  };

  const affordabilityLegends = getAffordLegends();

  const legends =
    affordabilitySelection.length > 0 && !isOpenedDrawer
      ? affordabilityLegends
      : ituLegends;

  const theme = JSON.parse(sessionStorage.getItem("theme"));

  // ClassNames
  const mapContainer = document.querySelector(".leaflet-container");
  const darkModeClass = "dark-map";
  const transparentPaneClass = "transparent-pane";

  const getCountries = async () => {
    const data = await fetchData(`${baseUrl}:8888/api/countries`, setMessage);
    setCountries(data);
  };

  const getItuLegends = async (layerLevel) => {
    const isLegendExist = existingLegends.find((data) => data.name === `${newCountryName}-${layerLevel}`);

    if (!isLegendExist) {
      const data = await fetchData(`${baseUrl}:8888/api/itu_legends?country_name=${newCountryName}&layer_level=${layerLevel}`, setMessage);

      setExistingLegends([
        ...existingLegends,
        { name: `${newCountryName}-${layerLevel}`, data },
      ]);
    }

    return null;
  };

  const getITUData = async (layerLevel) => {
    setLoading([indicator]);

    const isDataExist = existingData.find((data) => data.dataName === `${newCountryName}-${layerLevel}`);

    if (!isDataExist) {
      const data = await fetchData(`${baseUrl}:8888/api/itu_data?country_name=${newCountryName}&layer_level=${layerLevel}`, setMessage);

      setExistingData([
        ...existingData,
        {
          data,
          layerLevel: layerLevel,
          country: countryName,
          dataName: `${newCountryName}-${layerLevel}`,
        },
      ]);
    }

    setLoading([]);
    return null;
  };

  useEffect(() => {
    if ((!ituLegendData && year) || isOpenedDrawer) {
      getItuLegends(layerLevelPerCountry);
    }

    if (
      (layerLevel && year && indicator && affordabilitySelection.length === 0) ||
      isOpenedDrawer
    ) {
      getITUData(layerLevelPerCountry);
    }

    // eslint-disable-next-line
  }, [
    year,
    indicator,
    layerLevel,
    isOpenedDrawer,
    sliderData.layerLevel,
    affordabilitySelection.length,
  ]);

  // Make indicator layer transparent when wind is selected
  useEffect(() => {
    const hasWind = selectedDatasets.includes(WIND);
    if ((hasWind && indicator) || (hasWind && isOpenedDrawer)) {
      mapContainer?.classList.add(transparentPaneClass);
    } else {
      mapContainer?.classList.remove(transparentPaneClass);
    }
  }, [selectedDatasets, mapContainer, indicator, isOpenedDrawer]);

  useEffect(() => {
    sessionStorage.setItem("theme", JSON.stringify(darkMode));

    const body = document.querySelector("body");

    if (darkMode) {
      body.classList.add(darkModeClass);
    } else {
      body.classList.remove(darkModeClass);
    }
  }, [darkMode]);

  useEffect(() => {
    setDarkMode(theme);
    getCountries();
    checkExistingData(countryName, setMessage, setItuDataList);
    // eslint-disable-next-line
  }, []);

  return (
    <Context.Provider
      value={{
        map,
        setMap,
        darkMode,
        setDarkMode,
        position,
        setPosition,
        isShownPopulationInfo,
        setIsShownPopulationInfo,
        isShownModal,
        setIsShownModal,
        message,
        setMessage,
        isOpenedDrawer,
        setIsOpenedDrawer,
        sliderData,
        setSliderData,
        countries,
        countryName,
        setCountryName,
        indicatorData,
        setIndicatorData,
        selectedDatasets,
        setSelectedDatasets,
        existingData,
        setExistingData,
        cellTowerRange,
        setCellTowerRange,
        ituLegends,
        legends,
        existingLegends,
        affordabilityLegends,
        setExistingLegends,
        ituDataList,
        setItuDataList,
        cacheCleaner,
        setCacheCleaner,
        loading,
        setLoading,
        affordabilitySelection,
        setAffordabilitySelection,
        isLoadPdf,
        setIsLoadPdf
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
