import { BarChart, barLabelClasses, chartsGridClasses } from "@mui/x-charts";
import { CardHeader, Divider, Card } from "@mui/material";
import { highlightBar } from "../../../utils/charts";
import ChartLegends from "../../../components/ChartLegends/ChartLegends";
import { roundValue } from "../../../utils/numbers";

const getMargin = (hasData) => hasData ? { top: 5, right: 0.8 } : { top: 0, right: 0.8, left: 0.8 };
const getGrid = (hasData) => hasData ? { horizontal: true, vertical: true } : { horizontal: false, vertical: false };

const CardItem = ({
  title,
  data,
  series,
  className = "",
  height = 300,
  layout = "vertical",
  mainLegend,
  subLegend = [],
  hasData,
  loading,
  legendType = "",
  multipleData,
  isLoadPdf,
  onHighlightChange = () => null,
}) => {
  const cardClassName = `content-card ${className} ${hasData ? "valid-chart" : "empty-chart"}`;

  const barChartProps = {
    height,
    layout,
    series: highlightBar(series),
    margin: getMargin(hasData),
    grid: getGrid(hasData),
    tooltip: { trigger: "item" },
    loading,
    onHighlightChange,
    xAxis: [{
      data,
      scaleType: "band",
      barGapRatio: 0.08,
      categoryGapRatio: 0.1,
    }],
    yAxis: [{
      valueFormatter: (value) => `${value}%`,
      tickFontSize: 10,
    }],
    barLabel: (item, context) => context.bar.height < 20 ? null : `${roundValue(item?.value, "")}%`,
    slotProps: {
      legend: {
        hidden: true,
        itemGap: 20,
        labelStyle: { fontSize: 14 },
      },
    },
    sx: {
      [`& .${chartsGridClasses.horizontalLine}`]: {
        strokeDasharray: "2 3",
        strokeWidth: 1,
      },
      [`& .${barLabelClasses.root}`]: {
        fill: isLoadPdf ? "#aaaaaa" : "white",
        fontSize: 10,
        fontWeight: "bold",
        textShadow: "0px 0px 5px black",
      },
    },
  };

  return (
    <Card className={cardClassName}>
      <CardHeader title={title} className="card-header" />
      <Divider />
      <ChartLegends
        mainLegend={mainLegend}
        subLegend={subLegend}
        multipleData={multipleData}
        type={legendType === "multiple" ? "multiple" : ""}
      />
      <BarChart {...barChartProps} />
    </Card>
  );
};

export default CardItem;
