import {
  ACTIVE_MOBILE,
  CELL_TOWERS,
  CELL_TOWER_COUNT,
  EDUCATION,
  ELECTRICITY_GRID,
  FIBRE_OPTIC_LINES,
  FIXED_SPEED,
  HEALTHCARE,
  INTERNET_USERS,
  ROAD_NETWORK,
  countriesString,
} from "./strings";

const {
  CONGO,
  KAZAKHSTAN,
  KYRGYZSTAN,
  MOZAMBIQUE,
  MONGOLIA,
  NIGERIA,
  TAJIKISTAN,
  UZBEKISTAN,
} = countriesString;

export const fixedBroadbandDistrict = [
  {
    value: "district_fixed_2_10_year",
    subValue: "district_urbanyear.fixed_2_10_year",
  },
  {
    value: "district_fixed_above10_year",
    subValue: "district_ruralyear.fixed_above10_year",
  },
  {
    value: "district_fixed_256_2_year",
    subValue: "district_ruralyear.fixed_256_2_year",
  },
  {
    value: "district_fixedyear",
    subValue: "district_ruralyear.fixed_year",
  },
];

export const countries = [
  CONGO,
  KAZAKHSTAN,
  KYRGYZSTAN,
  MOZAMBIQUE,
  MONGOLIA,
  NIGERIA,
  TAJIKISTAN,
  UZBEKISTAN,
];

export const years = ["2020", "2030"];

export const layerlevels = ["province", "district"];

export const urbanizationClasses = ["rural", "urban"];

export const genders = ["female", "male"];

export const ages = ["adult", "young"];

export const indicatorPerLayer = [
  {
    id: 1,
    name: "2 - 10 Mbps",
    value: "_fixed_2_10_",
  },
  {
    id: 3,
    name: "256 Kbps - 2 Mbps",
    value: "_fixed_256_2_",
  },
  {
    id: 5,
    name: "> 10 Mbps",
    value: "_fixed_above10_",
  },
  { id: 7, name: "Any speed", value: "_fixed" },
  { id: 9, name: "Active Mobile broadband", value: "_mobile" },
  { id: 11, name: "Internet users", value: "_users" },
  {
    id: 13,
    name: "Gross income",
    value: "_gni",
  },
  {
    id: 15,
    name: "Population",
    value: "_pop",
  },
];
export const affordabilityPlan = {
  value: "cablefixed",
  subsection: [
    {
      id: 1,
      name: "Cheapest Fixed Plan",
      value: "cablefixedmin",
    },
    {
      id: 2,
      name: "Average Fixed Plan",
      value: "cablefixedavg",
    },
    {
      id: 3,
      name: "Most Expensive Fixed Plan",
      value: "cablefixedmax",
    },
    {
      id: 4,
      name: "Data-only Mobile-broadband (1GB)",
      value: "itu_mobile1gb",
    },
    {
      id: 5,
      name: "Data-only Mobile-broadband (2GB)",
      value: "itu_datamobile",
    },
    {
      id: 6,
      name: "Data-only Mobile-broadband (5GB)",
      value: "itu_mobile5gb",
    },
    {
      id: 7,
      name: "Data-only Mobile-broadband (10GB)",
      value: "itu_mobile10gb",
    },
  ],
};

export const initialBackgroundDatasets = [
  {
    id: 1,
    value: "relevant-infrastructures",
    category: "Relevant facilities",
    items: [
      {
        id: 1,
        value: HEALTHCARE,
        name: "Health facilities",
        data: null,
      },
      {
        id: 2,
        value: EDUCATION,
        name: "Education facilities",
        data: null,
      },
    ],
  },
  {
    id: 2,
    value: "broadband-infrastructure",
    category: "Broadband infrastructures",
    items: [
      {
        id: 1,
        value: CELL_TOWERS,
        name: "Cell towers",
        data: null,
      },
      {
        id: 2,
        value: FIBRE_OPTIC_LINES,
        name: "Fibre optic lines",
        data: null,
      },
    ],
  },
  {
    id: 3,
    value: "other-infrastructures",
    category: "Other infrastructures",
    items: [
      {
        id: 1,
        value: ELECTRICITY_GRID,
        name: "Electricity grid",
        data: null,
      },
      {
        id: 2,
        value: ROAD_NETWORK,
        name: "Road network",
        data: null,
      },
    ],
  },
  {
    id: 4,
    value: "natural-hazards",
    category: "Natural hazards",
    items: [
      {
        id: 1,
        value: "earthquakes-magnitude",
        name: "Earthquakes Magnitude",
        data: null,
      },
      {
        id: 2,
        value: "tsunami-event",
        name: "Tsunami Source Events",
        data: null,
      },
      {
        id: 3,
        value: "significant-earthquakes",
        name: "Significant Earthquakes",
        data: null,
      },
      {
        id: 4,
        value: "significant-volcanic-eruption",
        name: "Significant Volcanic Eruption",
        data: null,
      },
      {
        id: 5,
        value: "wind",
        name: "Wind",
        data: null,
      },
    ],
  },
];

export const drawerData = [
  { name: "Fixed broadband, any speed", value: FIXED_SPEED },
  { name: "Active mobile broadband", value: ACTIVE_MOBILE },
  { name: "Internet users", value: INTERNET_USERS },
  { name: "Cell towers", value: CELL_TOWER_COUNT },
];

export const aboutLinks = [
  "http://www.itu.int/en/ITU-D/Statistics/Pages/stat/default.aspx",
  "https://data.worldbank.org/indicator/NY.GNP.MKTP.CD?view=chart",
  "https://www.cable.co.uk/broadband/pricing/worldwide-comparison",
  "https://www.itu.int/en/ITU-D/Statistics/Pages/ICTprices/default.aspx",
  "https://afterfibre.nsrc.org",
  "https://ghsl.jrc.ec.europa.eu/datasets.php#inline-nav-ghs_pop2019",
  "https://ghsl.jrc.ec.europa.eu/ghs_smod2022.php",
  "https://location.services.mozilla.com",
  "http://opencellid.org/downloads",
  "https://www.ookla.com/ookla-for-good/open-data",
  "https://www.worldpop.org",
];

export const broadbandsDescription = [
  `&#9679; <b>Fixed broadband by speed: </b>
    Fixed Internet broadband subscriptions
    to the public Internet, split by advertised download speed. Fixed 
    services include DSL, Cable and Fibre. The number of subscriptions is 
    provided as a percentage of total population in each administrative 
    unit (i.e., district or province).`,
  `&#9679; <b>Active Mobile broadband: </b> Sum of standard mobile-broadband and 
    dedicated mobile-broadband subscriptions (actual subscribers) to the 
    public Internet. The number of subscriptions is provided as a 
    percentage of total population in each administrative unit (i.e., district
    or province).`,
  `&#9679; <b>Internet users: </b> Individuals using the Internet. The indicator is 
    provided as a percentage of the total population in each 
    administrative unit (i.e., district or province).`,
  `&#9679; <b>Gross income: </b> Sum of value added by all resident producers plus 
    any product taxes (less subsidies) not included in the valuation of 
    output plus net receipts of primary income (compensation of 
    employees and property income) from abroad. Data are in current 
    U.S. dollars. We provide per capita income as a proportion of total 
    population in each administrative unit (i.e., district or province).`,
  `&#9679; <b>Affordability: </b> Proportion of a person’s income that must be spent to 
    purchase a broadband plan measured as the % of the monthly income
    (USD) per capita. Affordability for fixed broadband is based on the 
    average, cheapest and most expensive package cost of fixed 
    broadband packages available in the country in year 2020. Average 
    cost of fixed broadband packages in year 2030 is based on 
    projections derived from the historical data series, sourced by 
    cable.com.uk. Affordability for mobile broadband is based on <a href='https://www.itu.int/en/ITU-D/Statistics/Pages/ICTprices/default.aspx' target='_blank' rel='noreferrer'>ITU ICT 
    Price Baskets</a>, which are internationally comparable units of ICT 
    services.`,
];

export const africanCountries = [
  "algeria",
  "angola",
  "benin",
  "botswana",
  "burkina faso",
  "burundi",
  "cabo verde",
  "cameroon",
  "central african republic",
  "chad",
  "comoros",
  "democratic republic of the congo",
  "republic of the congo",
  "djibouti",
  "egypt",
  "equatorial guinea",
  "eritrea",
  "eswatini",
  "ethiopia",
  "gabon",
  "gambia",
  "ghana",
  "guinea",
  "guinea-bissau",
  "ivory coast",
  "kenya",
  "lesotho",
  "liberia",
  "libya",
  "madagascar",
  "malawi",
  "mali",
  "mauritania",
  "mauritius",
  "morocco",
  "mozambique",
  "namibia",
  "niger",
  "nigeria",
  "rwanda",
  "sao tome and principe",
  "senegal",
  "seychelles",
  "sierra leone",
  "somalia",
  "south africa",
  "south sudan",
  "sudan",
  "tanzania",
  "togo",
  "tunisia",
  "uganda",
  "zambia",
  "zimbabwe",
];

export const internetSpeed = ["256 kbit/s to 2 Mbit/s", "2 Mbit/s to 10 Mbit/s", "Above 10 Mbit/s"];
export const broadbandPlans = ["Fixed Plans", "Mobile Broadband Plans"];
export const affordabilityPlans = [
  "Minimum / 1Gb",
  "Average / 5Gb",
  "Maximum / 10Gb",
];
export const broadbandSubscriptions = [
  "Fixed Broadband Subscriptions",
  "Mobile Broadband Suscriptions",
];
export const fixedPlans = ["Minimum", "Average", "Maximum"];
export const mobilePlan = ["1Gb", "5Gb", "10Gb"];
export const usersMobile = ["internet users", "mobile broadband suscriptions"];
export const activeMobileUser = ["Active Mobile Broadband", "Internet Users"];
