import { useContext, useState, useCallback } from "react";
import { Box, Slider, Typography } from "@mui/material";

import "./slider-item.scss";
import { Context } from "../../../GlobalContext";
import {
  ACTIVE_MOBILE,
  INTERNET_USERS,
  CELL_TOWER_COUNT,
  FIXED_SPEED,
} from "../../../utils/strings";

const initialValue = (value, isProvince) => {
  if (value.includes(ACTIVE_MOBILE)) {
    return [50, 70];
  } else if (value.includes(INTERNET_USERS)) {
    return [10, 30];
  }
  if (value === CELL_TOWER_COUNT) {
    if (isProvince) {
      return [2261, 12511];
    } else {
      return [327, 673];
    }
  } else {
    return [20, 40];
  }
};

const SliderItem = ({ name, value }) => {
  const { sliderData, setSliderData, cellTowerRange, ituLegends } = useContext(Context);

  const { year, layerLevel } = sliderData;
  const isCellTowers = value === CELL_TOWER_COUNT;
  const isProvince = layerLevel === "province";
  const [sliderValue, setSliderValue] = useState(initialValue(value, isProvince));

  const getRanges = (value) => {
    if (value.includes(ACTIVE_MOBILE)) {
      return `${layerLevel}_mobile${year}`;
    } else if (value.includes(INTERNET_USERS)) {
      return `${layerLevel}_users${year}`;
    } else if (value.includes(FIXED_SPEED)) {
      return `${layerLevel}_fixed${year}`;
    }
  };

  const getLegendRanges = () => {
    const ranges = getRanges(value);
    if (ituLegends && ituLegends[ranges]) {
      const { min, max } = ituLegends[ranges];
      return [...new Set([min, max].flat())].map((item) => Number(item));
    }
    return [];
  };

  const selectedRanges = getLegendRanges();

  // Create array of numbers based on the length of the returned range from getRanges(value)
  const createArrayOfNumbers = () =>
    Array.from({ length: selectedRanges?.length }, (_, index) => index * 10);

  const ranges = createArrayOfNumbers();

  const marks = ranges.map((range, index) => ({
    value: range,
    label: parseFloat(selectedRanges[index]?.toFixed(2) || 0),
  }));

  const minRange = isCellTowers ? cellTowerRange[0] : ranges[0];
  const maxRange = isCellTowers ? cellTowerRange[1] : ranges[ranges.length - 1];
  const sliderMark = !isCellTowers
    ? marks
    : [
        { value: cellTowerRange[0], label: cellTowerRange[0] },
        { value: cellTowerRange[1], label: cellTowerRange[1] },
      ];

  const sliderScale = (value) =>
    !isCellTowers ? marks.find((mark) => mark.value === value)?.label : value;

  // Get the value to control the map instead of the array of numbers returned from createArrayOfNumbers()
  const convertRange = (newValue) => {
    const convertedRange = newValue
      .map((value) => {
        return marks.map((mark) => {
          if (mark.value === value) {
            return mark.label;
          } else {
            return null;
          }
        });
      })
      .flat();

    const sliderRange = convertedRange.filter((value) => value !== null);

    return sliderRange.length === 1 || isCellTowers ? newValue : sliderRange;
  };

  const handleSliderChange = useCallback(
    (_event, newValue) => {
      setSliderValue(newValue);

      const updateSliderData = (property) => {
        setSliderData({ ...sliderData, [property]: convertRange(newValue) });
      };

      if (value.includes(ACTIVE_MOBILE)) {
        updateSliderData("activeMobile");
      }

      if (value.includes(INTERNET_USERS)) {
        updateSliderData("internetUsers");
      }

      if (value.includes(FIXED_SPEED)) {
        updateSliderData("anySpeed");
      }

      if (isCellTowers) {
        updateSliderData("cellTowers");
      }
    },
    // eslint-disable-next-line
    [sliderData, setSliderValue]
  );

  return selectedRanges?.length > 0 || value === CELL_TOWER_COUNT ? (
    <Box className="slider-item">
      <Typography component="h3" className="slider-label">
        {name}
      </Typography>

      <Box className="slider-item-wrapper">
        <Slider
          size="small"
          name={value}
          value={sliderValue}
          valueLabelDisplay="auto"
          getAriaLabel={() => name}
          min={minRange}
          max={maxRange}
          step={isCellTowers ? 1 : 10}
          marks={sliderMark}
          scale={sliderScale}
          onChange={handleSliderChange}
        />
      </Box>
    </Box>
  ) : (
    ""
  );
};

export default SliderItem;
